import React, { useEffect, useState } from "react";
import { debounce } from "lodash";
import { Link, Route } from "react-router-dom";
import { useHistory, useRouteMatch } from "react-router-dom";
import SelectAsync from "react-select/async";
import dayjs from "dayjs";

import Pagination from "../general/Pagination";
import Modal from "../general/Modal";
import Create from "./Create";
import Edit from "./Edit";
import Loader from "../general/Loader";
import { formatCurrency, getLink } from "../../utils/helpers";
import { apiRequest } from "../../hooks/apiRequest";

const Cheques = () => {

    const [state, setState] = useState({
        cheques: [],
        cheque: {},
        links: null,
        meta: null,
        showCreate: false,
        showEdit: false,
        loading: true,
        filtros: {
            buscar: "",
        },
    });

    const [filtros, setFiltros] = useState({
        buscar: "",
    });

    const history = useHistory();
    const match = useRouteMatch();

    const getCheques = async (url = null) => {
        url = url ? url + "&limit=20&activo=1" : "cheques?limit=20&activo=1";
        const cheques = await apiRequest(null, url, 'get');
        if (cheques.links) {
            setState({
                ...state,
                showCreate: false,
                cheques: cheques.data,
                links: cheques.links,
                meta: cheques.meta,
                loading: false,
            });
        }
    };

    const toggleCreateModal = () => {
        setState(state => ({
            ...state,
            showCreate: !state.showCreate,
            cheque: {},
        }));
    };

    const toggleEditModal = (cheque) => {
        setState({
            ...state,
            showEdit: !state.showEdit,
            cheque: cheque,
        });
        history.push(getLink("/cheques"));
    };

    const getOptions = async (url = null, label = 'nombre', value = 'id') => {
        const table = await apiRequest({}, url, 'get');
        if (table.data) {
            return table.data.map(item => ({value: item[value], label: item[label]}));
        }
    };

    const onFiltroChange = debounce(async (value, filtro) => {
        await setFiltros({
            ...filtros,
            [filtro]: value,
        });
    }, 500);

    const query = Object.keys(filtros).map((fil) => {
        return `${fil}=${filtros[fil]}`;
    });

    useEffect(() => {
        getCheques("cheques?limit=20&" + query.join("&"));
    }, [filtros]);

    const { cheques, loading } = state;

    return (
        <div className='container'>
            <div className='page-header'>
                <h1 className='page-title row'>
                    <span className='col'>Todos los Cheques</span>
                    <Link
                        to={getLink(`/imprimir/cheques?${query.join('&')}`)}
                        target='_blank'
                        className='au-btn-filter m-r-15'>
                        <i className='zmdi zmdi-print' /> Imprimir
                    </Link>
                    <button
                        className='au-btn au-btn-icon au-btn--green au-btn--small'
                        onClick={toggleCreateModal}>
                        <i className='zmdi zmdi-plus' />
                        Nuevo Cheque
                    </button>
                </h1>
                <div className='page-controls'>
                    <div className='table-data__tool'>
                        <div className='table-data__tool-left row'>
                            <div className='col'>
                                <label htmlFor="">Buscar</label>
                                <input
                                    onChange={(e) =>
                                        onFiltroChange(
                                            e.target.value,
                                            "buscar"
                                        )
                                    }
                                    type='search'
                                    placeholder='Buscar...'
                                    className='form-control'
                                    name='buscar'
                                />
                            </div>
                            <div className='col-md-3'>
                                <label htmlFor="cuenta_id">Cuenta</label>
                                <SelectAsync
                                    key="cuenta"
                                    onChange={(e) =>
                                        onFiltroChange(
                                            e?.value || '',
                                            "cuenta"
                                        )
                                    }
                                    isClearable
                                    placeholder='Seleccionar...'
                                    menuPosition='fixed'
                                    menuPlacement='auto'
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={(e) => getOptions("cuentas?buscar="+e)}
                                />
                            </div>
                            <div className='col-md-2'>
                                <label htmlFor="desde">Desde</label>
                                <input
                                    type="date"
                                    name="desde"
                                    id="desde"
                                    className="form-control"
                                    onChange={(e) =>
                                        onFiltroChange(e.target.value, "desde")
                                    }
                                />
                            </div>
                            <div className='col-md-2'>
                                <label htmlFor="hasta">Hasta</label>
                                <input
                                    type="date"
                                    id="hasta"
                                    className="form-control"
                                    onChange={(e) =>
                                        onFiltroChange(e.target.value, "hasta")
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <div>
                        {loading && <Loader />}
                        {!cheques.length ? (
                            <div>
                                {!loading && (
                                    <div>No hay cheques creados.</div>
                                )}
                            </div>
                        ) : (
                            <div className='table-data'>
                                <table className='table table-data2'>
                                    <thead>
                                        <tr>
                                            <th>Fecha</th>
                                            <th>Número</th>
                                            <th>Concepto</th>
                                            <th>Monto</th>
                                            <th>Cuenta</th>
                                            <th>Contacto</th>
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cheques.map((cheque) => (
                                            <tr
                                                className='tr-shadow'
                                                key={cheque.id}>
                                                <td>{dayjs(cheque.fecha).format('DD/MM/YYYY')}</td>
                                                <td>{cheque.numero}</td>
                                                <td>{cheque.concepto}</td>
                                                <td>{formatCurrency(cheque.monto)}</td>
                                                <td>{cheque.cuenta?.nombre}</td>
                                                <td>{cheque.contacto?.nombre}</td>
                                                <td>
                                                    <div className='table-data-feature'>
                                                        <Link
                                                            to={getLink(
                                                                `/cheques/${cheque.id}`
                                                            )}
                                                            className='item'
                                                            data-toggle='tooltip'
                                                            data-placement='top'
                                                            title=''
                                                            data-original-title='Edit'
                                                            onClick={() => {
                                                                toggleEditModal(
                                                                    cheque
                                                                );
                                                            }}>
                                                            <i className='zmdi zmdi-edit' />
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {state.links && (
                                    <Pagination
                                        links={state.links}
                                        meta={state.meta}
                                        getData={getCheques}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Route
                path='/:sucursal/cheques/:id'
                render={(props) => (
                    <Modal
                        show
                        blank
                        size="1048px"
                        toggle={toggleEditModal}>
                        <Edit
                            toggle={toggleEditModal}
                            cheque={
                                cheques.filter(
                                    (r) =>
                                        r.id ===
                                        parseInt(match.params.id)
                                )[0] || {}
                            }
                            getCheques={getCheques}
                        />
                    </Modal>
                )}
            />
            <Modal
                show={state.showCreate}
                blank={true}
                size="1048px"
                toggle={toggleCreateModal}>
                <Create
                    toggle={toggleCreateModal}
                    cheque={state.cheque}
                    getCheques={getCheques}
                />
            </Modal>
        </div>
    );
}

export default Cheques;
