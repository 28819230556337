import React, { useEffect, useState } from "react";
import Loader from "../../general/Loader";
import Modal from "../../general/Modal";
import Form from "../../Ausencias/Form";
import dayjs from "dayjs";
import { estadoAusenciaForDropdown, formatCurrency, getLink, getModalidadAusencia, modalidadAusenciaForDropdown, toast } from "../../../utils/helpers";
import useFilters from "../../../hooks/useFilters";
import { request } from "../../../utils/request";
import Pagination from "../../general/Pagination";
import FiltersContainer from "../../general/FiltersContainer";
import Select from "react-select";
import Summary from "./Summary";
import AjusteManual from "./AjusteManual";
import { Link } from "react-router-dom";
import { apiRequest } from "../../../hooks/apiRequest";
import Dropdown from "../../general/Dropdown";

const Ausencias = ({ empleado }) => {
    const estadoDefaultValue = estadoAusenciaForDropdown[4];

    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ausencias, setAusencias] = useState();
    const [ausencia, setAusencia] = useState({});
    const [tipoAusenciasForDropdown, setTipoAusenciasForDropdown] = useState([]);

    const [showManualModal, setShowManualModal] = useState(false);
    const [showSummary, setShowSummary] = useState(true);
    const [adquiridos, setAdquiridos] = useState(-1);
    const [disponibles, setDisponibles] = useState(-1);
    const [usados, setUsados] = useState(-1);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [ausenciaParaBorrar, setAusenciaParaBorrar] = useState({});

    const { handleFilterChange, filtersQuery } = useFilters({
		desde: null, hasta: null, modalidad: null, tipo: null, estado: estadoDefaultValue.value
	});

    const getTipos = () => {
        request("tipo-ausencia").then((response) => setTipoAusenciasForDropdown(response.data.map((tipo) => ({ value: tipo.id, label: tipo.nombre }))));
    };

    const openCreate = () => {
        setAusencia({ 
            desde: null,
            modalidad: null,
            cantidad_dia: null,
            contar_sabado: null,
            monto_pagar: null,
            descripcion: null,
            son_adquiridos: null,
            descontar_vacaciones: null,
            tipo_ausencia_id: null,
         });

        setShowModal(true);
    };

    const openEdit = (ausencia) => {
        setAusencia({ ...ausencia });

        setShowModal(true);
    };

    const closeModal = () => {
        setAusencia({});

        setShowModal(false);
    };

    const getAusencias = async (url) => {
        url = url ? url + "&limit=15" : `empleados/${empleado.id}/ausencias?limit=15`;

        setLoading(true);

		const response = await request(url);
        
		setAusencias(response);

		setLoading(false);
    };

    const success = (data, nueva) => {
        setShowSummary(false);
        closeModal();
        
        if (nueva) openCreate();

        getAusencias(`empleados/${empleado.id}/ausencias${filtersQuery}`);
        setShowSummary(true);
    };

    const openAjusteManual = (adquiridos, disponibles, usados) => {
        setShowManualModal(true);
        setShowSummary(false);
        setAdquiridos(adquiridos);
        setDisponibles(disponibles);
        setUsados(usados);
    };

    const closeManualModal = ({adquiridos, usados}) => {
        empleado.vacacionesSummary.adquiridos = adquiridos;
        empleado.vacacionesSummary.usados = usados;
        setShowManualModal(false);
        setShowSummary(true);
    };

    const openDelete = (ausencia) => {
        setAusenciaParaBorrar({ ...ausencia });

        setShowDeleteModal(true);
    };

    const closeDeleteModal = () => {
        setAusenciaParaBorrar({});

        setShowDeleteModal(false);
    };

    const cancel = async () => {
        setDeleting(true);
        setShowSummary(false);

        const response = await apiRequest({}, `ausencias/${ausenciaParaBorrar.id}`, 'delete');

        if (response.success) {
            toast("Ausencia Cancelada", "success");
            
            closeDeleteModal();
            
            closeModal();

            await getAusencias(`empleados/${empleado.id}/ausencias${filtersQuery}`);
        } else {
            toast(response.data.message, "error");
        }

        setDeleting(false);
        setShowSummary(true);
    };

    useEffect(() => {
        getTipos();
    }, []);

    useEffect(() => {
        getAusencias(`empleados/${empleado.id}/ausencias${filtersQuery}`);
    }, [empleado.id, filtersQuery]);

    return (
        <div>
            <div className='row m-b-20'>
                <div className='col'>
                    <h3>Ausencias</h3>
                </div>
                <div className='col text-right'>
                    <Link
                        className='au-btn au-btn-icon au-btn-filter au-btn--small mr-2'
                        to={getLink(
                            `/imprimir/empleados/${empleado.id}/ausencias${filtersQuery}`
                            )}
                        target='_blank'>
                        <i className='zmdi zmdi-print' />
                        Imprimir
                    </Link>

                    <button
                        onClick={openCreate}
                        className='au-btn au-btn-icon au-btn--green au-btn--small'>
                        <i className='zmdi zmdi-plus' />
                        NUEVA AUSENCIA
                    </button>
                </div>
            </div>

            <FiltersContainer>
                <div className='col'>
                    <label htmlFor='estado'>Estado</label>
                    <Select
                        name='estado'
                        isClearable={true}
                        onChange={(e) => handleFilterChange("estado", e?.value)}
                        options={estadoAusenciaForDropdown}
                        defaultValue={estadoDefaultValue}
                    />
                </div>

                <div className='col'>
                    <label htmlFor='tipo'>Tipo</label>
                    <Select
                        name='tipo'
                        isClearable={true}
                        onChange={(e) => handleFilterChange("tipo", e?.value)}
                        options={tipoAusenciasForDropdown}
                    />
                </div> 

                <div className='col'>
                    <label htmlFor='modalidad'>Modalidad</label>
                    <Select
                        name='modalidad'
                        isClearable={true}
                        onChange={(e) => handleFilterChange("modalidad", e?.value)}
                        options={modalidadAusenciaForDropdown}
                    />
                </div> 

                <div className='col'>
                    <label htmlFor='desde'>Desde</label>
                    <input onChange={(e) => handleFilterChange("desde", e.target.value)}
                        type="date"
                        className='form-control'
                        name='desde'
                        id="desde"
                    />
                </div> 

                <div className='col'>
                    <label htmlFor='hasta'>Hasta</label>
                    <input onChange={(e) => handleFilterChange("hasta", e.target.value)}
                        type="date"
                        className='form-control'
                        name='hasta'
                        id="hasta"
                    />
                </div> 
            </FiltersContainer>

            {showSummary && <Summary empleadoId={empleado.id} edit={openAjusteManual}/>}

            <div className='row'>
                <div className='col-md-12'>
                    <div>
                        <div className='table-data'>
                            <table className='table table-data2'>
                                <thead>
                                    <tr>
                                    <th>Tipo</th>
                                    <th>Modalidad</th>
                                    <th>Desde</th>
                                    <th>Hasta</th>
                                    <th style={{width: '60px', padding: '10px 5px'}}>Días</th>
                                    <th>Sábado</th>
                                    <th>Pagar</th>
                                    <th>Descripción</th>
                                    <th>Desc.</th>
                                    <th>Estado</th>
                                    <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {loading 
                                        ? (<tr className="tr-shadow"><td colSpan={12}><Loader /></td></tr>)
                                        : (ausencias?.data?.length
                                            ? ausencias.data.map((ausencia) => (
                                                <tr className="tr-shadow" key={ausencia.id}>
                                                    <td>{ausencia.tipo.nombre}</td>
                                                    <td>{getModalidadAusencia(ausencia.modalidad)?.label}</td>
                                                    <td>{dayjs(ausencia.desde).format("DD/MM/YYYY")}</td>
                                                    <td>{dayjs(ausencia.hasta).format("DD/MM/YYYY")}</td>
                                                    <td style={{width: '60px', padding: '5px'}}>
                                                        <span title="Total de diías">T-{ausencia.cantidad_dia}</span> <br/>
                                                        <span title="Días pendientes">P-{ausencia.restantes?.toString()}</span>
                                                    </td>
                                                    <td>{ausencia.contar_sabado === 0 ? "No Contar" : (ausencia.contar_sabado === 1 ? "Contar 1 Día" : "Contar 1/2 Día")}</td>
                                                    <td>{formatCurrency(ausencia.monto_pagar)}</td>
                                                    <td>
                                                        <div  style={{
                                                            maxWidth: '120px',
                                                            padding: '5px',
                                                            overflowWrap: 'break-word',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            maxHeight: 'calc(1.5em * 3.2)',
                                                            display: 'flex',
                                                        }}>
                                                            {ausencia.descripcion}
                                                        </div>
                                                    </td>
                                                    <td>{ausencia.descontar_vacaciones ? "Si" : "No"}</td>
                                                    <td style={{padding: '5px'}}>
                                                        { ausencia.estado === 'Pendiente' && <span className="badge badge-secondary p-2">Pendiente</span> }
                                                        { ausencia.estado === 'En curso' && <span className="badge badge-info p-2">En curso</span> }
                                                        { ausencia.estado === 'Completada' && <span className="badge badge-success p-2">Completada</span> }
                                                        { ausencia.estado === 'Cancelada' && <span className="badge badge-danger p-2">Cancelada</span> }
                                                    </td>
                                                    {ausencia.cancelada ? <td></td>
                                                        : <td style={{padding: '5px'}}>
                                                        <div className='table-data-feature'>
                                                            <Dropdown
                                                                classes={{ button: "w-100" }}
                                                                styles={{
                                                                    button: {
                                                                        height: "38px",
                                                                        lineHeight: "0px",
                                                                    },
                                                                    dropdownMenu: {
                                                                        zIndex: "99",
                                                                    },
                                                                }}
                                                            >
                                                                <Link
                                                                    className='dropdown-item'
                                                                    data-toggle='tooltip'
                                                                    data-placement='top'
                                                                    title=''
                                                                    data-original-title='Print'
                                                                    to={getLink(`/imprimir/ausencias/${ausencia.id}`)}
                                                                    target='_blank'>
                                                                    Imprimir
                                                                </Link>

                                                                <button
                                                                    className='dropdown-item'
                                                                    data-toggle='tooltip'
                                                                    data-placement='top'
                                                                    title=''
                                                                    data-original-title='Edit'
                                                                    onClick={() => openEdit(ausencia) }>
                                                                    Editar
                                                                </button>

                                                                { ausencia.estado !== 'Completada' && <>
                                                                    <button
                                                                        className='dropdown-item'
                                                                        data-toggle='tooltip'
                                                                        data-placement='top'
                                                                        title=''
                                                                        data-original-title='Cancel'
                                                                        onClick={() => openDelete(ausencia) }>
                                                                        Cancelar
                                                                    </button>
                                                                </> }

                                                                {ausencia.documentos?.length >= 1 && <li className="dropdown-divider"></li>}
                                                                {ausencia.documentos?.map(documento => (
                                                                    <a href={documento.url} className="dropdown-item" target="_blank" rel="noreferrer">
                                                                        {documento.nombre ?? `documento_${documento.id}`}
                                                                    </a>
                                                                ))}
                                                            </Dropdown>
                                                        </div>
                                                    </td> }
                                                </tr>)
                                            )
                                            : (<tr className="tr-shadow"><td colSpan={11}>No hay ninguna Ausencia creada.</td></tr>)
                                        )
                                    }
                                </tbody>
                            </table>

                            {ausencias?.links && (<Pagination links={ausencias.links} meta={ausencias.meta} getData={getAusencias} />)}
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={showModal}
                blank={true}
                toggle={() => setShowModal((state) => !state)}>
                <Form
                    empleado={empleado}
                    ausencia={ausencia}
                    cancel={closeModal}
                    success={success}
                    ondelete={openDelete}
                />
            </Modal>

            <Modal
                show={showManualModal}
                blank={true}
                toggle={() => setShowManualModal((state) => !state)}>
                <AjusteManual
                    empleado={empleado}
                    cancel={closeManualModal}
                    adquiridos={adquiridos}
                    disponibles={disponibles}
                    usados={usados}
                />
            </Modal>

            <Modal
                title="Cancelar Ausencia"
                show={showDeleteModal}
                blank={false}
                callback={cancel}
                toggle={() => setShowDeleteModal((state) => !state)}>
                <p className="px-3 pb-3">¿Estás seguro que desea cancelar esta ausencia? (Esta acción no se puede deshacer).</p>
            </Modal>
        </div>);
};

export default Ausencias;
