import React, { Component } from "react";
import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import {
	toast,
	formatCurrency,
	getLink,
	getSuplidoresForDropdown,
	permisions,
} from "../../utils/helpers";
import Loader from "../general/Loader";
import Modal from "../general/Modal";
// import ImageLoader from "../general/ImageLoader";
import Precios from "./Precios";
import { apiRequest } from "../../hooks/apiRequest";
import { ProductContext } from "./ProductContext";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import SelectAsync from "react-select/async";
import ReactSelect from "react-select";
import { postRequest } from "../../utils/request";

class Edit extends Component {
	state = {
		errors: {},
		showDelete: false,
		precios_venta: [],
		unidades: this.props.unidades,
		unidad: { value: this.context.unidad, label: this.context.unidad },
		costo: this.context.costo,
		isLoading: false,
		firstTaxSelected: null,
		allowPriceChange: false,
	};

	componentDidMount() {
		this.initFormData();
		this.costoField = React.createRef();
	}
	initFormData = () => {
		if (
			Object.keys(this.context).length <= 0 ||
			!this.context?.precios_venta
		) {
			setTimeout(() => {
				this.initFormData();
			}, 500);
			return;
		}

		const precios = this.context?.precios_venta?.map((precio) => ({
			id: precio.id,
			precio_venta: parseFloat(precio.precio_venta),
			costo: parseFloat(precio.porciento_beneficio),
			impuesto: precio.impuesto_id,
			precio_total: parseFloat(precio.total),
		}));

		this.setState({
			precios_venta: precios,
			unidad: { value: this.context.unidad, label: this.context.unidad },
			unidades: this.props.unidades,
		});
	};
	createUnidad = async (inputValue) => {
		this.setState({ isLoading: true });
		const formData = new FormData();
		formData.append("seccion", "inventario");
		formData.append("nombre", "unidad");
		formData.append("valor", inputValue.toUpperCase());

		const { success, data } = await apiRequest(
			formData,
			"store-config",
			"post"
		);

		if (success) {
			this.setState((state) => ({
				...state,
				unidad: { value: data.valor, label: data.valor },
				isLoading: false,
				unidades: [
					...state.unidades,
					{ value: data.valor, label: data.valor },
				],
			}));
		}
	};
	handleUnidadChange = (newValue) => {
		this.setState({ unidad: newValue });
		this.props.handleInputChange(newValue.value, "unidad");
	};
	editProducto = async (event) => {
		event.preventDefault();

		if (!auth.can(permisions.editarProductos)) return;

		const data = new FormData(event.target);
		const response = await postRequest(apiUrl(`productos/${this.context.id}`), data);

		if (response.success) {
			this.props.history.push(getLink("/productos"));
		}

		if (response.code === 422) {
			this.setState({ errors: response.data.message });
		}
	};
	toggleDeleteModal = () => {
		this.setState({
			showDelete: !this.state.showDelete,
		});
	};
	deleteProducto = async (producto) => {
		const response = await postRequest(apiUrl(`productos/${producto}`), {}, 'delete');

		if (response.success) {
			this.props.history.push(getLink("/productos"));
			toast("Producto borrado.");
			return;
		} else {
			toast(response.data, "error");
		}
	};
	addPrecio = () => {
		this.setState((state) => ({
			precios_venta: [
				...state.precios_venta,
				{
					id: Date.now(),
					precio_venta: 0,
					impuesto: 12,
					precio_total: 0,
					costo: 0,
				},
			],
		}));
	};
	removePrecio = async (producto) => {
		let res = await apiRequest(null, `precios/${producto}`, "delete");

		if (res.code === 201) {
			this.setState((state) => ({
				...state,
				precios_venta: state.precios_venta.filter(
					(precio) => precio.id !== producto
				),
			}));
		}
	};
	render() {
		const {
			errors,
			isLoading,
			unidades,
			unidad,
			firstTaxSelected,
			allowPriceChange,
		} = this.state;
		const { grupos, almacenes, impuestos, handleInputChange, producto } =
			this.props;

		const notEditable = !auth.can(permisions.editarProductos);

		if (this.context === {}) {
			return <Loader />;
		}

		const costoProducto = this.costoField?.current?.value || producto.costo;

		return (
			<div>
				<form
					method="post"
					onSubmit={this.editProducto}
					className="col-md-10"
				>
					<input type="hidden" name="_method" value="put" />
					<input
						type="hidden"
						name="producto_id"
						defaultValue={this.context?.id}
					/>
					<input
						type="hidden"
						name="tipo"
						defaultValue={this.context?.tipo}
					/>
					<div className="row">
						<div className="col-6">
							<div className="form-group">
								<label className="label form-control-label">
									Nombre:
								</label>
								<input
									type="text"
									className="text-input form-control"
									id="nombre"
									name="nombre"
									disabled={notEditable}
									onChange={(e) =>
										handleInputChange(
											e.target.value,
											"nombre"
										)
									}
									defaultValue={this.context?.nombre}
								/>
								{errors.nombre && (
									<small className="help-blockParams form-Text">
										{errors.nombre[0]}
									</small>
								)}
							</div>
							<div className="form-group">
								<label className="label form-control-label">
									Referencia:
								</label>
								<input
									type="text"
									className="text-input form-control"
									id="referencia"
									name="referencia"
									disabled={notEditable}
									onChange={(e) =>
										handleInputChange(
											e.target.value,
											"referencia"
										)
									}
									defaultValue={this.context.referencia}
								/>
								{errors.referencia && (
									<small className="help-blockParams form-Text">
										{errors.referencia[0]}
									</small>
								)}
							</div>
							{this.context.tipo === "producto" && (
								<div className="form-group">
									<label className="label form-control-label">
										Estado:
									</label>
									<select
										type="text"
										className="text-input form-control"
										id="estado"
										name="estado"
										disabled={notEditable}
										defaultValue={this.context.estado}
										onChange={(e) =>
											handleInputChange(
												e.target.value,
												"estado"
											)
										}
									>
										<option value="">
											- Seleccionar -
										</option>
										<option value="nuevo">Nuevo</option>
										<option value="usado">Usado</option>
									</select>
									{errors.estado && (
										<small className="help-blockParams form-Text">
											{errors.estado[0]}
										</small>
									)}
								</div>
							)}

							<div className="form-group">
								<label className="label form-control-label">
									Unidad:
								</label>
								<CreatableSelect
									options={unidades}
									name="unidad"
									isClearable
									isDisabled={isLoading}
									isLoading={isLoading}
									onChange={this.handleUnidadChange}
									value={unidad ? unidad : ""}
									onCreateOption={this.createUnidad}
								/>
								{/* <select name="unidad" className="form-control" defaultValue={unidad}>
                                    {unidades.map(und => (<option value={und.value}>{und.label}</option>))}
                                </select> */}
								{errors.unidad && (
									<small className="help-blockParams form-Text">
										{errors.unidad[0]}
									</small>
								)}
							</div>
							{this.context?.tipo === "producto" && (
								<div className="form-group">
									<label className="label form-control-label">
										Reorden:
									</label>
									<input
										type="number"
										name="reorden"
										min="0"
										className="form-control"
										value={producto.reorden}
										onChange={(e) =>
											handleInputChange(
												e.target.value,
												"reorden"
											)
										}
									/>
									{errors.estado && (
										<small className="help-blockParams form-Text">
											{errors.estado[0]}
										</small>
									)}
								</div>
							)}
						</div>
						<div className="col-6">
							{almacenes.length > 0 && this.context.almacen && (
								<div className="form-group">
									<label className="label form-control-label">
										Almacen:
									</label>
									<select
										className="text-input form-control"
										id="almacen_id"
										name="almacen_id"
										disabled={notEditable}
										onChange={(e) =>
											handleInputChange(
												e.target.value,
												"almacen_id"
											)
										}
										defaultValue={
											this.context.almacen
												? this.context.almacen.id
												: false
										}
									>
										<option value="">
											- Seleccionar -
										</option>
										{almacenes.map((almacen) => (
											<option
												key={almacen.id}
												value={almacen.id}
											>
												{almacen.nombre}
											</option>
										))}
									</select>
									{errors.almacen_id && (
										<small className="help-blockParams form-Text">
											{errors.almacen_id[0]}
										</small>
									)}
								</div>
							)}
							{grupos.length > 0 && this.context.tipo && (
								<div className="form-group">
									<label className="label form-control-label">
										Categoría:
									</label>
									<ReactSelect
										options={grupos.map((grupo) => ({
											value: grupo.id,
											label: grupo.nombre,
										}))}
										name="grupo_id"
										isClearable
										isDisabled={notEditable}
										isLoading={isLoading}
										defaultValue={
											this.context.grupo
												? {
														value: this.context
															.grupo.id,
														label: this.context
															.grupo.nombre,
												  }
												: null
										}
										onChange={(e) =>
											handleInputChange(
												e.value,
												"grupo_id"
											)
										}
									/>
									{errors.grupo_id && (
										<small className="help-blockParams form-Text">
											{errors.grupo_id[0]}
										</small>
									)}
								</div>
							)}
							{this.context?.tipo === "producto" && (
								<div className="form-group">
									<label className="label form-control-label">
										Suplidor:
									</label>
									<SelectAsync
										name="contacto_id"
										cacheOptions
										defaultOptions
										isClearable
										loadOptions={(e) =>
											getSuplidoresForDropdown(e)
										}
										defaultValue={{
											value: this.context?.suplidor?.id,
											label: this.context?.suplidor
												?.nombre,
										}}
									/>
									{errors.almacen_id && (
										<small className="help-blockParams form-Text">
											{errors.almacen_id[0]}
										</small>
									)}
								</div>
							)}
							<div className="form-group">
								<label className="label form-control-label">
									Descripción:
								</label>
								<textarea
									className="text-input form-control"
									id="descripcion"
									name="descripcion"
									rows="4"
									disabled={notEditable}
									defaultValue={this.context.descripcion}
									onChange={(e) =>
										handleInputChange(
											e.target.value,
											"descripcion"
										)
									}
								/>
								{errors.descripcion && (
									<small className="help-blockParams form-Text">
										{errors.descripcion[0]}
									</small>
								)}
							</div>
						</div>
					</div>
					<table className="table m-t-20">
						<thead>
							<tr>
								<th width="180px">Costo/Und:</th>
								<th width="180px">Impuesto</th>
								<th>Costo Total con Impuesto:</th>
								<th>En Inventario:</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td
									onDoubleClick={() => {
										this.setState({
											allowPriceChange: true,
										});
									}}
								>
									{!allowPriceChange ? (
										<>
											{formatCurrency(this.context.costo)}
											<i className="fa fa-pen table-edit-field color-primary"></i>{" "}
											<input
												type="hidden"
												ref={this.costoField}
												value={this.context.costo || 0}
											/>
										</>
									) : (
										<input
											type="number"
											className="form-control"
											name="costo"
											ref={this.costoField}
											value={producto.costo}
											onChange={(e) =>
												handleInputChange(
													e.target.value,
													"costo"
												)
											}
										/>
									)}
								</td>
								<td>
									{/* {this.context?.impuesto?.nombre} */}
									{!allowPriceChange ? (
										<>{this.context?.impuesto?.nombre}</>
									) : (
										<Select
											id="impuesto_id"
											name="impuesto_id"
											defaultValue={
												this.context?.impuesto
													? impuestos.find(
															(impuesto) =>
																impuesto.value ===
																this.context
																	?.impuesto
																	?.id
													  )
													: null
											}
											onChange={(e) =>
												handleInputChange(e, "impuesto")
											}
											options={impuestos}
										/>
									)}
								</td>
								<td>
									{console.log("costo", costoProducto)}
									{formatCurrency(
										parseFloat(costoProducto) +
											parseFloat(costoProducto) *
												this.context?.impuesto
													?.porcentaje
									)}
									<input
										type="hidden"
										name="costo_total"
										value={
											parseFloat(costoProducto) +
												parseFloat(costoProducto) *
													this.context?.impuesto
														?.porcentaje || 0
										}
									/>
								</td>
								<td>
									{this.context.inventariable ? (
										this.context.cantidad
									) : (
										<span>&infin;</span>
									)}
								</td>
							</tr>
						</tbody>
					</table>
					<fieldset className="fieldset">
						<legend>Precios de venta:</legend>
						<div>
							{this.state?.precios_venta?.map((precio, index) => (
								<Precios
									precio={precio}
									precios={this.state.precios_venta}
									index={index}
									key={index}
									costo={this.costoField}
									removePrecio={this.removePrecio}
									impuestos={impuestos}
									rowClasses="w-100"
									accion="editar"
									firstTaxSelected={firstTaxSelected}
									// setParentState={(e) => this.setState({firstTaxSelected: e})}
								/>
							))}
						</div>
						{errors.precios && (
							<small className="help-blockParams form-Text">
								{errors.precios[0]}
							</small>
						)}
						<div className="text-right">
							<button
								type="button"
								onClick={() => this.addPrecio()}
								className="btn"
							>
								<i className="fas fa-plus" /> Agregar Precio
							</button>
						</div>
					</fieldset>

					<div className="page-footer text-right">
						{!notEditable && (
							<button
								type="button"
								className="btn float-left"
								onClick={this.toggleDeleteModal}
							>
								<i className="fas fa-trash-alt"></i>
							</button>
						)}
						<button
							type="button"
							onClick={() =>
								this.props.history.push(getLink("/productos"))
							}
							className="btn btn-plain"
						>
							Cancelar
						</button>
						{!notEditable && (
							<button type="submit" className="btn btn-primary">
								Guardar
							</button>
						)}
					</div>
					{/* <ImageLoader
						name="fotos[]"
						imagenes={producto.fotos || []}
						multiple
						onInputChange={handleInputChange}
						// className="d-none"
					/>		 */}
				</form>
				<Modal
					title="Borrar Producto"
					show={this.state.showDelete}
					callback={() => this.deleteProducto(this.context.id)}
					acceptText="Borrar"
					toggle={this.toggleDeleteModal}
				>
					¿Estás seguro de que deseas borrar este producto?
				</Modal>
			</div>
		);
	}
}
Edit.contextType = ProductContext;

export default Edit;
