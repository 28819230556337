import React, { useEffect, useState } from "react";
import { request } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import Select from "react-select";
import Permisos from "./Permisos";

const Form = ({usuario, errors, sucursales}) => {
	const [empleados, setEmpleados] = useState([]);
	const [sucursalesUsuario, setSucursalesUsuario] = useState([]);
	const rol = usuario?.rol ? (usuario.rol === 'personalizado' ? null : usuario?.rol) : null;
	
	const getVendedoresForDropdown = () => {
		request("empleados?limit=100").then((res) => {
			setEmpleados(res.data.map((empleado) => ({
				value: empleado.id,
				label: `${empleado.codigo} ${empleado.nombre} ${empleado.apellido}`,
			})));
		});
	};

	const changeSelectedSucursales = (values) => {
		setSucursalesUsuario(values);
	};

	useEffect(()=> {
		getVendedoresForDropdown();
		setSucursalesUsuario(usuario?.sucursales?.map(
			(sucursal) => ({
				value: sucursal.id,
				label: sucursal.nombre,
			})
		));
	}, []);

	const esVendedor = rol ? (rol.includes("vendedor") || rol.includes("cobrador")) : (usuario?.es_vendedor || false);

	return (
		<div className="row">
			<input
				type="hidden"
				className="text-input form-control"
				id="role"
				name="role"
				value={'personalizado'}
			/>
			<div className="col-7">
				<div className="row form-group">
					<div className="col col-md-5">
						<label className="label form-control-label">
							Nombre de Usuario:
						</label>
					</div>
					<div className="col-12 col-md-7">
						<input
							type="text"
							className="text-input form-control"
							id="username"
							name="username"
							defaultValue={usuario.usuario}
						/>
						{errors.username && (
							<small className="help-blockParams form-Text">
								{errors.username[0]}
							</small>
						)}
					</div>
				</div>
				<div className="row form-group">
					<div className="col col-md-5">
						<label className="label form-control-label">
							Nombre:
						</label>
					</div>
					<div className="col-md-7">
						<input
							type="text"
							className="text-input form-control"
							id="nombre"
							name="nombre"
							defaultValue={usuario.nombre}
						/>
						{errors.nombre && (
							<small className="help-blockParams form-Text">
								{errors.nombre[0]}
							</small>
						)}
					</div>
				</div>
				<div className="row form-group">
					<div className="col col-md-5">
						<label className="label form-control-label">
							Apellido:
						</label>
					</div>
					<div className="col-md-7">
						<input
							type="text"
							className="text-input form-control"
							id="apellido"
							name="apellido"
							defaultValue={usuario.apellido}
						/>
						{errors.apellido && (
							<small className="help-blockParams form-Text">
								{errors.apellido[0]}
							</small>
						)}
					</div>
				</div>
				<div className="row form-group">
					<div className="col col-md-5">
						<label className="label form-control-label">
							Email
						</label>
					</div>
					<div className="col-md-7">
						<input
							type="email"
							className="text-input form-control"
							id="email"
							name="email"
							defaultValue={usuario.email}
						/>
						{errors.email && (
							<small className="help-blockParams form-Text">
								{errors.email[0]}
							</small>
						)}
					</div>
				</div>

				<div className="row form-group">
					<div className="col col-md-5">
						<label className="label form-control-label">
							Es vendedor:
						</label>
					</div>
					<div className="col-md-7">
						<label className="switch switch-3d switch-success mr-3">
							<input
								key={usuario.id}
								type="checkbox"
								className="switch-input"
								id="es_vendedor"
								name="es_vendedor"
								value="yes"
								defaultChecked={esVendedor ? true : false}
							/>
							<span className="switch-label" />
							<span className="switch-handle" />
						</label>
					</div>
				</div>

				<div className="row form-group">
					<div className="col col-md-5">
						<label className="label form-control-label">
							Empleado:
						</label>
					</div>
					<div className="col-md-7">
						<Select
							name="empleado_id"
							options={empleados}
							defaultValue={
								usuario.empleado && {
									value: usuario.empleado?.id,
									label: `${usuario.empleado?.codigo} ${usuario.empleado?.nombre} ${usuario.empleado?.apellido}`,
								}
							}
							styles={{
								control: (styles) => ({
									...styles,
									height: "38px",
								}),
							}}
						/>
						{errors.empleado_id && (
							<small className="help-blockParams form-Text">
								{errors.empleado_id[0]}
							</small>
						)}
					</div>
				</div>

				<div className="row form-group">
					<div className="col col-md-5">
						<label className="label form-control-label">
							Activo:
						</label>
					</div>
					<div className="col-md-7">
						<label className="switch switch-3d switch-success mr-3">
							<input
								key={usuario.id}
								type="checkbox"
								className="switch-input"
								id="active"
								name="active"
								value="yes"
								defaultChecked={usuario.estado ? true : false}
							/>
							<span className="switch-label" />
							<span className="switch-handle" />
						</label>
					</div>
				</div>
				{!auth.is("admin") && (
					<div className="row form-group">
						<div className="col col-md-5">
							<label className="label form-control-label">
								Sucursales
							</label>
						</div>
						<div className="col-md-7">
							<Select
								options={sucursales.map((sucursal) => ({
									value: sucursal.id,
									label: sucursal.nombre,
								}))}
								isMulti
								name="sucursales[]"
								onChange={(e) =>
									changeSelectedSucursales(e)
								}
								value={sucursalesUsuario}
								menuPortalTarget={document.body}
								styles={{
									menuPortal: (base) => ({
										...base,
										zIndex: 9999,
									}),
								}}
							/>
						</div>
					</div>
				)}

				{auth.is("admin") && (
					<input
						type="hidden"
						name="sucursales[]"
						value={auth.getUser().sucursalActual}
					/>
				)}

				<div className="row form-group">
					<div className="col col-md-5">
						<label className="label form-control-label">
							Contraseña
						</label>
					</div>
					<div className="col-md-7">
						<input
							type="password"
							className="text-input form-control"
							id="password"
							name="password"
						/>
						{errors.password && (
							<small className="help-blockParams form-Text">
								{errors.password[0]}
							</small>
						)}
					</div>
				</div>
				<div className="row form-group">
					<div className="col col-md-5">
						<label className="label form-control-label">
							Confirmar Contraseña
						</label>
					</div>
					<div className="col-md-7">
						<input
							type="password"
							className="text-input form-control"
							id="password_confirmation"
							name="password_confirmation"
						/>
					</div>
				</div>
			</div>

			<div className="col-5">
				<Permisos permisosUsuario={usuario.permisos} rol={rol} />
			</div>
		</div>
	);
};

export default Form;
