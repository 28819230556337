import React, { Component } from "react";
import { Route, NavLink, withRouter } from "react-router-dom";
import { withRequest } from "../../utils/request";
import Loader from "../general/Loader";
import Edit from "./Edit";
import Ajustes from "./Ajustes";
import { getLink, permisions } from "../../utils/helpers";
import { ProductContext } from "./ProductContext";
import EditFotos from "./EditFotos";
import { auth } from "../../utils/auth";
import Compras from "./Compras";

class Layout extends Component {
	state = {
		producto: {},
		loading: true,
		almacenes: [],
		grupos: [],
		impuestos: [],
		unidades: [
			{ value: "UND", label: "UND" },
			{ value: "PAQUETE", label: "PAQUETE" },
			{ value: "JUEGO", label: "JUEGO" },
			{ value: "PIES", label: "PIES" },
			{ value: "PULG", label: "PULG" },
			{ value: "MTS", label: "MTS" },
			{ value: "CM", label: "CM" },
			{ value: "GLS", label: "GLS" },
			{ value: "LATA", label: "LATA" },
			{ value: "CUBETA", label: "CUBETA" },
			{ value: "CUARTOS", label: "CUARTOS" },
			{ value: "LITROS", label: "LITROS" },
			{ value: "LBS", label: "LBS" },
			{ value: "KGS", label: "KGS" },
		],
	};

	componentDidMount() {
		this.setState({
			loading: true,
		});
		this.getUnidades();
		this.getImpuestos();
		this.getTable("grupos");
		this.getTable("almacenes");
		this.getProducto();
	}

	getProducto = async (url = null) => {
		const id = this.props.match.params.id;
		url = url ? url : "productos/" + id;
		const producto = await this.props.request(url);

		if (producto.success) {
			this.setState({
				producto: producto.data,
				loading: false,
			});
		}
	};

	getImpuestos = async (url = null) => {
		const id = this.props.match.params.id;
		url = url ? url : "impuestos-dropdown";
		const impuestos = await this.props.request(url);

		if (impuestos.success) {
			this.setState({
				impuestos: impuestos.data,
				loading: false,
			});
		}
	};

	getTable = async (url = null) => {
		const table = await this.props.request(url);

		if (table.data) {
			this.setState({
				[url]: table.data,
				loading: false,
			});
		}
	};
	getUnidades = async () => {
		const unidades = await this.props.request("get-unidades");

		if (unidades.success) {
			this.setState((state) => ({
				unidades: [...state.unidades, ...unidades.data],
			}));
			return;
		}
	};
	handleInputChange = (value, field) => {
		this.setState((state) => ({
			...state,
			producto: { ...state.producto, [field]: value },
		}));
	};
	render() {
		const { loading, producto, almacenes, grupos, impuestos, unidades } =
			this.state;

		if (loading) {
			return <Loader />;
		}

		return (
			<div>
				<div className="page-header">
					<h1 className="page-title">
						{producto.referencia} - {producto.nombre}
					</h1>
					
				</div>
				<div className="row">
					<div className="col-md-3 col-lg-2 page-sidebar">
						<ul className="list-unstyled page-sidebar__menu">
							<li>
								<NavLink
									to={getLink(
										`/productos/${producto.id}/informacion`
									)}
									exact
									className="nav-link"
								>
									Información General
								</NavLink>
								{auth.can(permisions.editarProductos) && (
									<li>
										<NavLink
											to={getLink(
												`/productos/${producto.id}/compras`
											)}
											exact
											className="nav-link"
										>
											Compras
										</NavLink>
									</li>
								)}
							</li>

							<li>
								<NavLink
									to={getLink(
										`/productos/${producto.id}/ajustes`
									)}
									exact
									className="nav-link"
								>
									Ajustes
								</NavLink>
							</li>
							{producto.fotos && producto.tipo === "producto" && (
								<li>
									<NavLink
										to={getLink(
											`/productos/${producto.id}/fotos`
										)}
										exact
										className="nav-link"
									>
										Fotos
									</NavLink>
								</li>
							)}
						</ul>
					</div>
					{ producto.id ? <div className="col p-l-45">
						<Route
							path="/:sucursal/productos/:id/informacion"
							render={(props) => (
								<ProductContext.Provider value={producto}>
									<Edit
										{...props}
										producto={producto}
										almacenes={almacenes}
										grupos={grupos}
										impuestos={impuestos}
										unidades={unidades}
										handleInputChange={
											this.handleInputChange
										}
									/>
								</ProductContext.Provider>
							)}
						/>
						<Route
							path="/:sucursal/productos/:id/compras"
							render={(props) => (
								<Compras {...props} producto={producto} />
							)}
						/>
						<Route
							path="/:sucursal/productos/:id/fotos"
							render={(props) => (
								<EditFotos
									{...props}
									producto={producto}
									impuestos={impuestos}
									handleInputChange={this.handleInputChange}
								/>
							)}
						/>
						<Route
							path="/:sucursal/productos/:id/ajustes"
							render={(props) => (
								<Ajustes
									{...props}
									producto={producto}
									getProducto={this.getProducto}
								/>
							)}
						/>
					</div> : <div className="col p-l-45"> <Loader /> </div> }
				</div>
			</div>
		);
	}
}

export default withRouter(withRequest(Layout));
