import cookie from "js-cookie";
import { apiUrl } from "./apiUrl";
import { permisions } from "./helpers";

export const auth = {
    getUser: () => {
        let user = window.localStorage.getItem("user");
        user = JSON.parse(user);
        user.sucursalActual = parseInt(
            window.localStorage.getItem("sucursalActual")
        );
        return user;
    },
    getToken: () => {
        return cookie.get("token");
    },
    is: (rol) => {
        return auth.getUser().rol === rol;
    },
    can: (ability) => {
        const rol = auth.getUser().rol;

        //soporte para Roles
        if (rol) {
            const puede = auth.permissions[rol]?.indexOf(ability) > -1;

            // si el rol tiene la habilidad retornar true else validar tenga el permiso
            if (puede) return puede;
        } 
        
        return (auth.getUser().permisos ?? []).includes(ability);
    },
    canEdit: (ability) => {
        const val = `${ability}-no-editar`;

        return !auth.can(val);
    },
    getConfig: () => {
        const predeterminado = {
            unidad_produccion_p: 'Toneladas',
            unidad_produccion_s: 'Tonelada',
            unidad_produccion_abr: 'TON.',
        }

        const config = JSON.parse(window.localStorage.getItem("config"));

        return config.unidad_produccion_p ? config : predeterminado;
    },
    permissions: {
        "super-admin": [
            permisions.editarUsuarios,
            permisions.verDashboard,
            permisions.abrirTarjeta,
            permisions.verConfiguracion,
            permisions.verProgramacion,
            permisions.verEmpleados,
            permisions.verNomina,
            permisions.verSolicitudes,
            permisions.verReclamaciones,
            permisions.verDescuentos,
            permisions.verIncentivos,
            permisions.verVehiculos,
            permisions.verRutas,
            permisions.verInventario,
            permisions.verContratos,
            permisions.editarInventario,
            permisions.verGastosGenerales,
            permisions.verGastos,
            permisions.verCompras,
            permisions.verServicios,
            permisions.verReportes,
            permisions.verContactos,
            permisions.editarSucursales,
            permisions.verTipos,
            permisions.verCuentas,
            permisions.verGiGrupos,
            permisions.verGiPrestamos,
            permisions.verGiServidores,
            permisions.verGrupoTiposEmpleados,
            permisions.verIngresos,
            permisions.verFacturas,
            permisions.verFacturasRecurrentes,
            permisions.verOrdenesCompra,
            permisions.verCotizaciones,
            permisions.verComprobantes,
            permisions.verProductos,
            permisions.editarProductos,
            permisions.verGastosInternos,
            permisions.verPlantillasImpresion,
            permisions.verConsultas,
            permisions.verCuentasPorCobrar,
            permisions.verRecibosIngreso,
            permisions.verAcademico,
            permisions.verCuentasPorPagar,
            permisions.verEvaluaciones,
            permisions.verEstudiantes,
            permisions.verInscripcion,
            permisions.verFacturacionAcademica,
            permisions.verPadrestutores,
            permisions.verContabilidad,
            permisions.verNotasCredito,
        ],
        admin: [
            permisions.verDashboard,
            permisions.abrirTarjeta,
            permisions.verConfiguracion,
            permisions.verProgramacion,
            permisions.verEmpleados,
            permisions.verNomina,
            permisions.verSolicitudes,
            permisions.verReclamaciones,
            permisions.verDescuentos,
            permisions.verIncentivos,
            permisions.verVehiculos,
            permisions.verRutas,
            permisions.verInventario,
            permisions.editarInventario,
            permisions.verGastos,
            permisions.verGastosGenerales,
            permisions.verGastosInternos,
            permisions.verCompras,
            permisions.verContratos,
            permisions.verServicios,
            permisions.verReportes,
            permisions.verContactos,
            permisions.verTipos,
            permisions.verCuentas,
            permisions.verGiGrupos,
            permisions.verGiPrestamos,
            permisions.verGiServidores,
            permisions.verGrupoTiposEmpleados,
            permisions.verProductos,
            permisions.editarProductos,
            permisions.verComprobantes,
            permisions.verIngresos,
            permisions.verFacturas,
            permisions.verFacturasRecurrentes,
            permisions.verCotizaciones,
            permisions.verOrdenesCompra,
            permisions.verPlantillasImpresion,
            permisions.verConsultas,
            permisions.verCuentasPorCobrar,
            permisions.verCuentasPorPagar,
            permisions.verRecibosIngreso,
            permisions.verAcademico,
            permisions.verEvaluaciones,
            permisions.verEstudiantes,
            permisions.verInscripcion,
            permisions.verFacturacionAcademica,
            permisions.verPadrestutores,
            permisions.verContabilidad,
            permisions.verNotasCredito,
        ],
        rrhh: [
            permisions.verDashboard,
            permisions.verConfiguracion,
            permisions.verProgramacion,
            permisions.verEmpleados,
            permisions.verNomina,
            permisions.verSolicitudes,
            permisions.verReclamaciones,
            permisions.verVehiculos,
            permisions.verRutas,
            permisions.verInventario,
            permisions.editarInventario,
            permisions.verGastos,
            permisions.verGastosGenerales,
            permisions.verGastosInternos,
            permisions.verCompras,
            permisions.verServicios,
            permisions.verReportes,
            permisions.verContactos,
        ],
        nomina: [
            permisions.verEmpleados,
            permisions.verTipos,
            permisions.verNomina,
            permisions.verDescuentos,
            permisions.verIncentivos,
            permisions.verGiGrupos,
            permisions.verGiPrestamos,
            permisions.verGiServidores,
        ],
        editor: [
            permisions.verProgramacion,
            permisions.verIngresos,
            permisions.verFacturas,
            permisions.verVehiculos,
            permisions.verCotizaciones,
            permisions.verOrdenesCompra,
            permisions.verDescuentos,
            permisions.verServicios,
            permisions.verInventario,
            permisions.editarInventario,
            permisions.verCompras,
            permisions.verGastos,
            permisions.verGastosGenerales,
            permisions.verGastosInternos,
            permisions.verIncentivos,
        ],
        compras: [
            permisions.verCompras,
            permisions.verSolicitudes,
            permisions.verReclamaciones,
            permisions.verGastos,
            permisions.verGastosGenerales,
            permisions.verGastosInternos,
            permisions.verInventario,
            permisions.verProductos,
            permisions.verServicios,
            permisions.verNotasCredito,
        ],
        gasto: [
            permisions.verSolicitudes,
            permisions.verReclamaciones,
            permisions.verGastos,
            permisions.verGastosGenerales,
            permisions.verInventario,
            permisions.verServicios,
        ],
        digitador: [
            permisions.verSolicitudes,
            permisions.verReclamaciones,
            permisions.verEmpleados,
            permisions.verProgramacion
        ],
        solicitudes: [
            permisions.verSolicitudes,
        ],
        vendedor: [
            permisions.verIngresos,
            permisions.verOrdenesCompra,
            permisions.verCotizaciones,
            permisions.verConsultas,
            permisions.verCuentasPorCobrar,
            permisions.verRecibosIngreso
        ],
        "vendedor-cliente": [
            permisions.verIngresos,
            permisions.verOrdenesCompra,
            permisions.verCotizaciones,
            permisions.verConsultas,
            permisions.verCuentasPorCobrar,
            permisions.verRecibosIngreso
        ],
        cobrador: [
            permisions.verOrdenesCompra,
            permisions.verCotizaciones,
            permisions.verConsultas,
            permisions.verCuentasPorCobrar,
            permisions.verRecibosIngreso,
        ],
        "vendedor-admin": [
            permisions.verIngresos,
            permisions.verOrdenesCompra,
            permisions.verFacturas,
            permisions.verCotizaciones,
            permisions.verConsultas,
            permisions.verCuentasPorCobrar,
            permisions.verRecibosIngreso
        ],
        "vendedor-factura": [
            permisions.verIngresos,
            permisions.verFacturas,
            permisions.verConsultas,
            permisions.verCuentasPorCobrar,
            permisions.verRecibosIngreso
        ],
        "vendedor-factura-orden_compra": [
            permisions.verIngresos,
            permisions.verFacturas,
            permisions.verOrdenesCompra,
            permisions.verConsultas,
            permisions.verCuentasPorCobrar,
            permisions.verRecibosIngreso
        ],
        'edu.caja': [
            permisions.verIngresos,
            permisions.verFacturas,
            permisions.verFacturasRecurrentes,
            permisions.verConsultas,
            permisions.verOrdenesCompra,
            permisions.verCuentasPorCobrar,
            permisions.verRecibosIngreso,
            permisions.verContactos,
            permisions.verAcademico,
            permisions.verEvaluaciones,
            permisions.verEstudiantes,
            permisions.verInscripcion,
            permisions.verPadrestutores,
        ],
        'edu.recepcion': [
            permisions.verAcademico,
            permisions.verEvaluaciones,
            permisions.verEstudiantes,
            permisions.verInscripcion,
            permisions.verPadrestutores,
        ],
        'contratos': [
            permisions.verContratos,
            permisions.verContactos,
        ],
    },
    async authenticate(credentials, cb) {
        const { access_token, user, expires_at } = credentials;

        cookie.set("token", access_token, { expires: new Date(expires_at) });
        window.localStorage.setItem("user", JSON.stringify(user));
        window.localStorage.setItem("sucursalActual", user.sucursalActual);
        window.localStorage.setItem("config", JSON.stringify(user.config));
        cb();
    },
    async logout() {
        const token = cookie.get("token");
        cookie.remove("token");

        await fetch(apiUrl("salir"), {
            method: "post",
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token,
            },
        });

        // to support logging out from all windows
        window.localStorage.setItem("logout", Date.now());
        window.localStorage.removeItem("sucursalAcual");
        window.localStorage.removeItem("config");
        window.localStorage.removeItem("user");
        window.localStorage.removeItem("rutas");
        window.localStorage.removeItem("almacenes");

        window.location = "/";
    },
    isLoggedIn() {
        const token = cookie.get("token");
        if (!token) {
            return false;
        }
        return token;
    },
    inactivityTime() {
        var time;
        window.onload = resetTimer;

        // DOM Events
        document.onmousemove = resetTimer;
        document.onkeypress = resetTimer;
        document.addEventListener("scroll", resetTimer, true);

        function logout() {
            clearTimeout(time);
            auth.logout();
        }

        function resetTimer() {
            clearTimeout(time);

            if (auth.isLoggedIn()) {
                time = setTimeout(logout, 60000 * 60);
                return;
            }
        }
    },
};
