import React from "react";
import { Link } from "react-router-dom";
import { auth } from "../../utils/auth";
import { getLink, permisions } from "../../utils/helpers";

const Configuracion = (props) => {
    const user = auth.getUser();
    const sucursalActual = user.sucursales.filter(s => s.id === user.sucursalActual)[0];
    return (
        <div className='container'>
            <div className='page-header'>
                <h1 className='page-title'>Configuración</h1>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='table-data'>
                        {sucursalActual.tipo === 'educativa' && auth.can(permisions.verAcademico) && (
                            <div>
                                <table className='table table-data2'>
                                    <tbody>
                                        <tr className='tr-shadow'>
                                            <td>
                                                <Link to={getLink("/configuracion/academica")} className="d-block">
                                                    Académica
                                                    <i className='zmdi zmdi-mail-send float-right'></i>
                                                </Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <hr />
                            </div>
                        )}
                        <table className='table table-data2'>
                            <tbody>
                                <tr className='tr-shadow'>
                                    <td>
                                        <Link to={getLink("/configuracion/almacenes")} className="d-block">
                                            Almacenes
                                            <i className='zmdi zmdi-mail-send float-right'></i>
                                        </Link>
                                    </td>
                                </tr>
                                <tr className='tr-shadow'>
                                    <td>
                                        <Link to={getLink("/configuracion/asignaciones")} className="d-block">
                                            Asignaciones
                                            <i className='zmdi zmdi-mail-send float-right'></i>
                                        </Link>
                                    </td>
                                </tr>
                                <tr className='tr-shadow'>
                                    <td>
                                        <Link to={getLink("/configuracion/taxonomias")} className="d-block">
                                            Categorías
                                            <i className='zmdi zmdi-mail-send float-right'></i>
                                        </Link>
                                    </td>
                                </tr>
                                <tr className='tr-shadow'>
                                    <td>
                                        <Link to={getLink("/configuracion/contratos")} className="d-block">
                                            Categorías de Contratos
                                            <i className='zmdi zmdi-mail-send float-right'></i>
                                        </Link>
                                    </td>
                                </tr>
                                <tr className='tr-shadow'>
                                    <td>
                                        <Link to={getLink("/configuracion/grupos")} className="d-block">
                                            Categorías de Productos
                                            <i className='zmdi zmdi-mail-send float-right'></i>
                                        </Link>
                                    </td>
                                </tr>
                                <tr className='tr-shadow'>
                                    <td>
                                        <Link to={getLink("/configuracion/categorias")} className="d-block">
                                            Categorías Vehículos
                                            <i className='zmdi zmdi-mail-send float-right'></i>
                                        </Link>
                                    </td>
                                </tr>
                                <tr className='tr-shadow'>
                                    <td>
                                        <Link to={getLink("/configuracion/impuestos")} className="d-block">
                                            Impuestos
                                            <i className='zmdi zmdi-mail-send float-right'></i>
                                        </Link>
                                    </td>
                                </tr>
                                <tr className='tr-shadow'>
                                    <td>
                                        <Link to={getLink("/configuracion/numeraciones")} className="d-block">
                                            Numeración de Documentos
                                            <i className='zmdi zmdi-mail-send float-right'></i>
                                        </Link>
                                    </td>
                                </tr>
                                <tr className='tr-shadow'>
                                    <td>
                                        <Link to={getLink("/configuracion/plantillas-impresion")} className="d-block">
                                            Plantillas de impresión
                                            <i className='zmdi zmdi-mail-send float-right'></i>
                                        </Link>
                                    </td>
                                </tr>
                                <tr className='tr-shadow'>
                                    <td>
                                        <Link to={getLink("/configuracion/terminos-pago")} className="d-block">
                                            Términos de Pago
                                            <i className='zmdi zmdi-mail-send float-right'></i>
                                        </Link>
                                    </td>
                                </tr>
                                <tr className='tr-shadow'>
                                    <td>
                                        <Link to={getLink("/configuracion/razones-notas-credito")} className="d-block">
                                            Razones de Notas de Crédito
                                            <i className='zmdi zmdi-mail-send float-right'></i>
                                        </Link>
                                    </td>
                                </tr>
                                <tr className='tr-shadow'>
                                    <td>
                                        <Link to={getLink("/configuracion/tipos")} className="d-block">
                                            Tipos Empleados
                                            <i className='zmdi zmdi-mail-send float-right'></i>
                                        </Link>
                                    </td>
                                </tr>
                                <tr className='tr-shadow'>
                                    <td>
                                        <Link to={getLink("/configuracion/tipo-ausencias")} className="d-block">
                                            Tipos Ausencias
                                            <i className='zmdi zmdi-mail-send float-right'></i>
                                        </Link>
                                    </td>
                                </tr>
                                {auth.can(permisions.editarSucursales) && (
                                    <tr className='tr-shadow'>
                                        <td>
                                            <Link to={getLink("/sucursales")} className="d-block">
                                                Sucursales
                                                <i className='zmdi zmdi-mail-send float-right'></i>
                                            </Link>
                                        </td>
                                    </tr>
                                )}
                                {auth.can(permisions.editarUsuarios) && (
                                    <tr className='tr-shadow'>
                                        <td>
                                            <Link to={getLink("/configuracion/usuarios")} className="d-block">
                                                Usuarios
                                                <i className='zmdi zmdi-mail-send float-right'></i>
                                            </Link>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Configuracion;
