import React, { Component } from "react";
import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import { getLink, permisions } from "../../utils/helpers";
import Loader from "../general/Loader";
import ImageLoader from "../general/ImageLoader";
import { postRequest } from "../../utils/request";

class EditFotos extends Component {
	state = {
		errors: {},
		showDelete: false,
		precios_venta: [],
	};
	componentDidMount() {
		setTimeout(() => {
			if (this.props.item?.precios_venta) {
				this.setState((state) => ({
					...state,
					precios_venta: this.props.item?.precios_venta?.map(
						(precio) => ({
							id: precio.id,
							precio_venta: parseFloat(precio.precio_venta),
							costo: parseFloat(precio.porciento_beneficio),
							impuesto: precio.impuesto_id,
							precio_total: parseFloat(precio.total),
						})
					),
				}));
			}
		}, 500);
	}
	editItem = async (event) => {
		event.preventDefault();

		if (!auth.can(permisions.editarInventario)) return;

		const data = new FormData(event.target);
		const response = await postRequest(apiUrl(`items/${this.props.item.id}`), data);

		if (response.success) {
			this.props.history.push(getLink("/inventario"));
		}

		if (response.code === 422) {
			this.setState({ errors: response.data.message });
		}
	};
	render() {
		const { errors } = this.state;
		const { item } = this.props;

		const notEditable = !auth.can(permisions.editarInventario);

		if (item === {}) {
			return <Loader />;
		}

		return (
			<div>
				<form
					method="post"
					onSubmit={this.editItem}
					className="col-md-10"
				>
					<input type="hidden" name="_method" value="patch" />
					<input type="hidden" name="item_id" value={item.id} />
					<input type="hidden" value={item.tipo} name="tipo" />
					<input type="hidden" name="nombre" value={item.nombre} />
					<input type="hidden" name="referencia" value={item.referencia} />
					<input type="hidden" name="estado" value={item.estado} />
					<input type="hidden" name="unidad" value={item.unidad} />
					<input type="hidden" name="grupo_id" value={item.grupo?.id} />
					<input type="hidden" name="almacen_id" value={item.almacen?.id} />
					<input type="hidden" name="impuesto_id" value={item.impuesto?.id} />
					<input type="hidden" id="descripcion" name="descripcion" rows="4" value={item.descripcion} />
					<input type="hidden" value={item.costo} />
					<div className="d-none">
						{this.state?.precios_venta?.map((precio, index) => (<>
								<input type="hidden" name={`precios[${index}][id]`} value={precio.id} />
								<input type="hidden" name={`precios[${index}][impuesto_id]`} value={precio.impuesto} />
								<input type="hidden" name={`precios[${index}][costo]`} value={precio.costo} />
								<input type="hidden" name={`precios[${index}][total]`} value={precio.precio_total} />
								<input type="hidden" name={`precios[${index}][precio_venta]`} value={precio.precio_venta} />
							</>))}
					</div>
					{item.tipo === "item" && (
						<div className="form-group">
							<label className="label form-control-label">
								Fotos:
							</label>
							<ImageLoader
								name="fotos[]"
								imagenes={item.fotos.map(foto => foto.url) || []}
								multiple
							/>
							{errors.fotos && (
								<small className="help-blockParams form-Text">
									{errors.fotos[0]}
								</small>
							)}
						</div>
					)}

					<div className="page-footer text-right">
						<button
							type="button"
							onClick={() =>
								this.props.history.push(getLink("/inventario"))
							}
							className="btn btn-plain"
						>
							Cancelar
						</button>
						{!notEditable && (
							<button type="submit" className="btn btn-primary">
								Guardar
							</button>
						)}
					</div>
				</form>
			</div>
		);
	}
}

export default EditFotos;
