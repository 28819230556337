import React, { useState } from 'react';
import Select from 'react-select';
import SelectAsync from "react-select/async";

import { bancos, formatCurrency, getCuentasForDropdown, money } from '../../../utils/helpers';
import PrefixedInput from '../../general/PrefixedInput';
import { METODOS_PAGO } from '../../../utils/types';

const PagoForm = ({
    pagoInfo,
    setPagoInfo,
    isEdit,
    setPorPagar,
    form,
    sumatoriaFinal,
    condicionPago = () => {},
}) => {
    const totalImpuestos = Object.keys(sumatoriaFinal.impuestos).reduce((p,c) => {
        return p + parseFloat(sumatoriaFinal.impuestos[c]);
    }, 0);

    const [retencion, setRetencion] = useState(money(totalImpuestos * 0.3));

    return (<div>
            <div className="form-group">
                <label>Tipo de pago</label>
                <select
                    className="form-control"
                    name="tipo_pago"
                    value={pagoInfo.tipo_pago}
                    onChange={(e) => {
                        e.persist();
                        setPagoInfo((state) => ({
                            ...state,
                            terminal_tarjeta: "",
                            tipo: "visa",
                            banco: "",
                            num_aprobacion: "",
                            num_cheque: "",
                            num_transferencia: "",
                            num_transaccion: "",
                            num_deposito: "",
                            tipo_pago: e.target.value,
                        }));
                    }}
                >
                    {METODOS_PAGO.map((metodo) => (
                        <option
                            value={metodo.value}
                            key={metodo.value}
                        >
                            {metodo.label}
                        </option>
                    ))}
                </select>
            </div>
            {pagoInfo.tipo_pago === "cheque" && (
                <ChequeInfo
                    pagoInfo={pagoInfo}
                    setPagoInfo={setPagoInfo}
                />
            )}
            {pagoInfo.tipo_pago === "tarjeta" && (
                <TarjetaInfo
                    pagoInfo={pagoInfo}
                    setPagoInfo={setPagoInfo}
                />
            )}
            {pagoInfo.tipo_pago === "transferencia" && (
                <TransferenciaInfo
                    pagoInfo={pagoInfo}
                    setPagoInfo={setPagoInfo}
                />
            )}
            {pagoInfo.tipo_pago === "deposito" && (
                <DepositoInfo
                    pagoInfo={pagoInfo}
                    setPagoInfo={setPagoInfo}
                />
            )}
            {pagoInfo.tipo_pago === "otro" && (
                <OtroInfo
                    pagoInfo={pagoInfo}
                    setPagoInfo={setPagoInfo}
                />
            )}
            <div className="form-group">
                <label>Monto Recibido</label>
                <PrefixedInput
                    type="number"
                    prefix="RD$"
                    name="pago"
                    value={pagoInfo.pago}
                    setValue={(e) =>
                        setPagoInfo((state) => ({
                            ...state,
                            pago: e,
                        }))
                    }
                    onBlur={(e) => condicionPago(e)}
                />
                <input
                    type="hidden"
                    min="0"
                    className="form-control"
                    value={formatCurrency(pagoInfo.pago)}
                    onChange={(e) => {
                        e.persist();
                        setPagoInfo((state) => ({
                            ...state,
                            pago: e.target.value,
                        }));
                    }}
                />
                <input
                    type="hidden"
                    name="pago"
                    value={pagoInfo.pago}
                />
            </div>
            <div className="form-group">
                <label>Cuenta de Destino</label>
                <SelectAsync
                    name="cuenta_destino"
                    cacheOptions
                    defaultOptions
                    menuPosition='fixed'
                    menuPlacement='auto'
                    loadOptions={(value) => getCuentasForDropdown(value)}
                    placeholder="Seleccione..."
                    onChange={(e) => {
                        setPagoInfo((state) => ({
                            ...state,
                            cuenta: e,
                        }));
                    }}
                    value={pagoInfo.cuenta}
                />
                <input
                    type="hidden"
                    name="cuenta"
                    value={pagoInfo.cuenta?.value}
                />
            </div>
            <div
                className="row"
                style={{ justifyContent: "space-around" }}
            >
                <div
                    className="col-5 text-center"
                    style={{
                        border: "1px solid #cdd2d7",
                        borderRadius: "7px",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                        cursor: "pointer",
                    }}
                    onClick={() => setPorPagar()}
                >
                    <h5 className="mb-2">Total Factura:</h5>
                    {isEdit ? (
                        <p>
                            {formatCurrency(
                                money(
                                    form.porCobrar -
                                        form.prevTotal +
                                        sumatoriaFinal.total
                                ) > 0
                                    ? money(
                                            form.porCobrar -
                                                form.prevTotal +
                                                sumatoriaFinal.total
                                    )
                                    : 0
                            )}
                        </p>
                    ) : (
                        <p>
                            {formatCurrency(
                                money(sumatoriaFinal.total)
                            )}
                        </p>
                    )}
                </div>
                <div
                    className="col-5 text-center"
                    style={{
                        border: "1px solid #cdd2d7",
                        borderRadius: "7px",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                    }}
                >
                    <h5 className="mb-2">Devuelta:</h5>
                    <p>
                        {parseFloat(pagoInfo.pago) > parseFloat(sumatoriaFinal.total)
                            ? formatCurrency(
                                    money(
                                        pagoInfo.pago -
                                            sumatoriaFinal.total
                                    )
                            )
                            : 0}
                    </p>
                </div>
        </div>
        <div className="form-group m-t-15">
            <label><input type="checkbox" className='m-r-10' onChange={() => {setPagoInfo(prev => ({...prev, usarRetencion: !prev.usarRetencion}))}} />Retención</label>
            {pagoInfo.usarRetencion && (
                <PrefixedInput
                    type="number"
                    prefix="RD$"
                    name="retencion"
                    value={retencion}
                    setValue={(e) => setRetencion(e)}
                />
            )}
        </div>
    </div>
    );
}

export default PagoForm;

export const TarjetaInfo = ({ pagoInfo, setPagoInfo }) => {
	return (
		<>
			<div className="form-group">
				<label>Terminal de tarjeta</label>
				<input
					type="text"
					name="terminal_tarjeta"
					value={pagoInfo.terminal_tarjeta}
					onChange={(e) => {
						e.persist();
						setPagoInfo((state) => ({
							...state,
							terminal_tarjeta: e.target.value,
						}));
					}}
					className="form-control"
				/>
			</div>
			<div className="form-group">
				<label>Tipo</label>
				<select
					className="form-control"
					name="tipo"
					value={pagoInfo.tipo}
					onChange={(e) => {
						e.persist();
						setPagoInfo((state) => ({
							...state,
							tipo: e.target.value,
						}));
					}}
				>
					<option value="visa">Visa</option>
					<option value="mastercard">Mastercard</option>
					<option value="otro">Otro</option>
				</select>
			</div>
			<div className="form-group">
				<label className="label form-control-label">
					Institución Financiera:
				</label>
				<Select
					isClearable
					options={bancos}
					name="banco"
					onChange={(e) => {
						setPagoInfo((state) => ({
							...state,
							banco: e.value,
						}));
					}}
				/>
			</div>
			<div className="form-group">
				<label>Numero de aprobación</label>
				<input
					type="text"
					className="form-control"
					name="num_aprobacion"
					value={pagoInfo.num_aprobacion}
					onChange={(e) => {
						e.persist();
						setPagoInfo((state) => ({
							...state,
							num_aprobacion: e.target.value,
						}));
					}}
				/>
			</div>
		</>
	);
};

export const ChequeInfo = ({ pagoInfo, setPagoInfo }) => {
	return (
		<>
			<div className="form-group">
				<label>Numero de cheque</label>
				<input
					type="text"
					name="num_cheque"
					className="form-control"
					value={pagoInfo.num_cheque}
					onChange={(e) => {
						e.persist();
						setPagoInfo((state) => ({
							...state,
							num_cheque: e.target.value,
						}));
					}}
				/>
			</div>
			<div className="form-group">
				<label className="label form-control-label">
					Institución Financiera:
				</label>
				<Select
					isClearable
					options={bancos}
					name="banco"
					onChange={(e) => {
						setPagoInfo((state) => ({
							...state,
							banco: e.value,
						}));
					}}
				/>
			</div>
		</>
	);
};

export const TransferenciaInfo = ({ pagoInfo, setPagoInfo }) => {
	return (
		<>
			<div className="form-group">
				<label>Numero de Transferencia</label>
				<input
					type="text"
					name="num_transferencia"
					className="form-control"
					value={pagoInfo.num_transferencia}
					onChange={(e) => {
						e.persist();
						setPagoInfo((state) => ({
							...state,
							num_transferencia: e.target.value,
						}));
					}}
				/>
			</div>
			<div className="form-group">
				<label className="label form-control-label">
					Institución Financiera:
				</label>
				<Select
					isClearable
					options={bancos}
					name="banco"
					onChange={(e) => {
						setPagoInfo((state) => ({
							...state,
							banco: e.value,
						}));
					}}
				/>
			</div>
		</>
	);
};

export const DepositoInfo = ({ pagoInfo, setPagoInfo }) => {
	return (
		<>
			<div className="form-group">
				<label>Numero de Deposito</label>
				<input
					type="text"
					name="num_deposito"
					className="form-control"
					value={pagoInfo.num_deposito}
					onChange={(e) => {
						e.persist();
						setPagoInfo((state) => ({
							...state,
							num_deposito: e.target.value,
						}));
					}}
				/>
			</div>
			<div className="form-group">
				<label className="label form-control-label">
					Institución Financiera:
				</label>
				<Select
					isClearable
					options={bancos}
					name="banco"
					onChange={(e) => {
						setPagoInfo((state) => ({
							...state,
							banco: e.value,
						}));
					}}
				/>
			</div>
		</>
	);
};

export const OtroInfo = ({ pagoInfo, setPagoInfo }) => {
	return (
		<>
			<div className="form-group">
				<label>Numero de transaccion</label>
				<input
					type="text"
					name="num_transaccion"
					className="form-control"
					value={pagoInfo.num_transaccion}
					onChange={(e) => {
						e.persist();
						setPagoInfo((state) => ({
							...state,
							num_transaccion: e.target.value,
						}));
					}}
				/>
			</div>
		</>
	);
};
