import React, { useEffect, useRef, useState } from "react";
import { auth } from "../../utils/auth";
import Form from "./Form";
import { apiUrl } from "../../utils/apiUrl";
import { toast, validarPINSeguridad } from "../../utils/helpers";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { apiRequest } from "../../hooks/apiRequest";
import Loader from "../general/Loader";
import { postRequest } from "../../utils/request";
import Modal from "../general/Modal";

const Edit = ({getCheques, toggle}) => {

    const [state, setState] = useState({
        cheque: {},
        loading: true,
        saving: false,
    });

    const [errors, setErrors] = useState({});
    const [afectarCuentas, setAfectarCuentas] = useState(true);

    const [showPINModal, setShowPINModal] = useState(false);
    const [pinSeguridad, setPinSeguridad] = useState("");
    const [accion, setAccion] = useState("");

    const formRef = useRef();

    const match = useRouteMatch();

    const getCheque = async () => {
        const id = match.params.id;
        const cheque = await apiRequest(null, 'cheques/' + id, 'get');
        if (cheque.data) {
            setState({
                ...state,
                cheque: cheque.data,
                loading: false,
            });
        }
    }

    const { cheque, loading, saving } = state;

    const checkPINSeguridad = async () => {
		if (await validarPINSeguridad(pinSeguridad)) {
			setPinSeguridad("");
			setShowPINModal(false);
			
            if (accion === 'editar') {
                formRef.current.dispatchEvent(
                    new Event("submit", { bubbles: true, cancelable: true })
                );
            } else if(accion === "eliminar") {
                deleteCheque(cheque.id);
            } else if(accion === "anular") {
                anularCheque(cheque.id);
            }
            

			return;
		}

		toast("PIN incorrecto, intente de nuevo", "error");
	};

    const editar = (event) => {
        event.preventDefault();

        setAccion("editar");
        setShowPINModal(true);
    }
    const eliminar = (event) => {
        event.preventDefault();

        setAccion("eliminar");
        setShowPINModal(true);
    }
    const anular = (event) => {
        event.preventDefault();

        setAccion("anular");
        setShowPINModal(true);
    }

    const editCheque = async (event) => {
        event.preventDefault();

        setState({ ...state, saving: true });

        const data = new FormData(event.target);
        const id = match.params.id;

        const response = await apiRequest(data, `cheques/${id}`);

        if (response.success) {
            toast('Cheque actualizado.');
            toggle();
            getCheques();
        }

        if (response.code === 422) {
            setErrors(response.data.message);
            toast('Error editando cheque.', 'error');
        }

        setState({ ...state, saving: false });
    };

    const deleteCheque = async (cheque) => {
        setState({ ...state, saving: true });

        const response = await postRequest(apiUrl(`cheques/${cheque}`), {}, 'delete');

        if (response.success) {
            getCheques();
            toggle();
            toast("Cheque borrado.");
            return;
        } else {
            toast(response.data, "error");
        }

        setState({ ...state, saving: false });
    };

    const anularCheque = async (cheque) => {
        setState({ ...state, saving: true });

        const response = await postRequest(apiUrl(`cheques/${cheque}/anular`));

        if (response.success) {
            getCheques();
            toggle();
            toast("Cheque anulado.");
            return;
        } else {
            toast(response.data, "error");
        }

        setState({ ...state, saving: false });
    }

    useEffect(() => {
        getCheque();
    }, []);

    useEffect(() => {
        setAfectarCuentas(state.cheque.movimientos?.length > 0)
    }, [state]);

    useEffect(() => {
        setPinSeguridad("");

        if (!showPINModal) {
            setAccion("");
        }
    }, [showPINModal]);



    if (loading) {
        return (<Loader />)
    }
    return (
        <>
            <form method='post' onSubmit={editCheque} ref={formRef}>
                <input type='hidden' name='_method' value='patch' />
                <div className='popup__header'>
                    Editar Cheque
                    <div className="float-right">
                        <small className="m-r-15">Afectar cuentas contables</small>
                        <label className='switch switch-3d switch-success mr-3'>
                            <input type='hidden' name='afectar_cuentas' value='no' />
                            <input
                                key={cheque.id}
                                type='checkbox'
                                className='switch-input'
                                id='afectar_cuentas'
                                name='afectar_cuentas'
                                value='yes'
                                checked={afectarCuentas}
                                onChange={() => setAfectarCuentas(!afectarCuentas)}
                            />
                            <span className='switch-label' />
                            <span className='switch-handle' />
                        </label>
                    </div>
                </div>
                <div className='popup__body'>
                    <Form cheque={cheque} errors={errors} afectarCuentas={afectarCuentas} />
                </div>
                <div className='popup__footer'>
                    <button
                        type='button'
                        className='btn float-left'
                        onClick={eliminar}>
                        <i className='fas fa-trash-alt'></i>
                    </button>

                    <button
                        type='button'
                        onClick={toggle}
                        className='btn btn-plain'>
                        Cancelar
                    </button>

                    <button
                        type='button'
                        onClick={anular}
                        className='btn btn-plain'>
                        Anular
                    </button>
                    {saving ? (
                        <Loader type='small' />
                    ) : (
                        <button type='button' className='btn btn-primary' onClick={editar}>
                            Crear
                        </button>
                    )}
                </div>
            </form>

            {/* <---PIN DE SEGURIDAD MODAL--- */}
            <Modal
                title="PIN de seguridad"
                show={showPINModal}
                callback={() => checkPINSeguridad()}
                acceptText="Aceptar"
                toggle={() => setShowPINModal((state) => !state)}
                size="419px"
            >
                <div className="form-group">
                    <label>PIN de seguridad</label>
                    <input
                        type="password"
                        className="form-control"
                        id="PIN"
                        name="pin_seguridad"
                        value={pinSeguridad}
                        onChange={(e) =>
                            setPinSeguridad(e.target.value)
                        }
                    />
                    <p
                        className="font-weight-bold"
                        style={{
                            color: "gray",
                            fontStyle: "italic",
                            lineHeight: "13px",
                            fontSize: "0.7rem",
                        }}
                    >
                        Para continuar ingrese PIN de seguridad
                    </p>
                </div>
            </Modal>
        </>
    );
}

export default Edit;
