import React, { useEffect, useState } from "react";
import {Link, useHistory } from "react-router-dom";

import { auth } from "../../utils/auth";
import { getLink, getSucursal, permisions } from "../../utils/helpers";
import { request } from "../../utils/request";

const MobileHeader = () => {
	const [sucursalActual, setSucursalActual] = useState(getSucursal());
	const [showMenu, setshowMenu] = useState(false);

	let history = useHistory();

	function toggleMenu() {
		setshowMenu(!showMenu);
		hideSidebar();
	}
	function hideSidebar(e = {}) {
		const sidebar = document.getElementById("sidebar");
		
		if(!sidebar.classList.contains('d-none')) {
            sidebar.classList.add('d-none');
			
        }
	}

    function toggleSidebar() {
		setshowMenu(false)
        const sidebar = document.getElementById("sidebar");
		const modals = document.getElementsByClassName('zone-selector');
		const modalZoneSelector = modals.length ? modals[0] : null;

        if(sidebar.classList.contains('d-none')) {
            sidebar.classList.remove('d-none');
			modalZoneSelector?.classList.add('open-sidebar');
        } else {
            sidebar.classList.add('d-none');
			modalZoneSelector?.classList.remove('open-sidebar');
        }
    }

	const setSucursal = async (sid) => {
        try {
            const res = await request("sset?sid=" + sid);

            if (res.success) {
                for (var i = 0; i < localStorage.length; i++) {
                    if (localStorage.key(i) !== 'user') {
                        localStorage.removeItem(localStorage.key(i));
                    }
                }

                window.localStorage.setItem("sucursalActual", res.data.id);
                window.localStorage.setItem("config", JSON.stringify(res.data.config));
                setSucursalActual(res.data.id);
                history.push("/" + res.data.id);
				window.location = "/" + res.data.id;
            } else {
                console.error("Error al cambiar de sucursal:", res.error);
            }
        } catch (error) {
            console.error("Error al cambiar de sucursal:", error);
        }
    };
	const handleConfiguracionClick = () => {
        setshowMenu(false); 
	}
    const usuario = auth.getUser();
    const sucursalActualData = usuario.sucursales.find(s => s.id === parseInt(sucursalActual));

    useEffect(() => {
        const sidebar = document.getElementById("sidebar");
        sidebar.addEventListener("onmouseout", toggleSidebar());
		hideSidebar();

        return () => {
            sidebar.removeEventListener("onmouseout", toggleSidebar());
        }
        
    }, [sucursalActual]);

	return (
		<header className="header-mobile d-block d-lg-none">
			<div className="header-mobile__bar">
				<div className="container-fluid">
					<div className="header-mobile-inner">
						<a className="logo" href={getLink("/")}>
							<img
								src="/images/icon/logo-recolector.png"
								alt="SAE Logo"
							/>
						</a>
						<div className="nombre-sucursal">
                            {sucursalActualData && sucursalActualData.nombre}
                        </div>
						<div className="d-flex">
							<button
								onClick={() => toggleSidebar()}
								className="hamburger hamburger--slider"
								type="button"
							>
								<span className="hamburger-box">
									<span className="hamburger-inner"></span>
								</span>
							</button>
							<button
								onClick={() => toggleMenu()}
								className="hamburger hamburger--slider"
								type="button"
							>
								<i className="fas fa-power-off"></i>
								{/* <i className="fas fa-chevron-circle-down"></i> */}
							</button>
						</div>
					</div>
				</div>
			</div>
			<nav className={"navbar-mobile " + (showMenu ? "show" : "")}>
				<div className="container-fluid">
					<ul className="navbar-mobile__list list-unstyled">
						<div className="account-dropdown__footer">
							{usuario.sucursales.map((sucursal) => (
								<li key={sucursal.id}>
                                    {/* <div className="account-dropdown__footer"> */}
                                        <button
                                            className={
                                                parseInt(getSucursal()) ===
                                                sucursal.id
                                                    ? "active"
                                                    : ""
                                            }
                                            onClick={() => setSucursal(sucursal.id)}
                                        >
                                            {sucursal.nombre}
                                        </button>
                                    {/* </div> */}
								</li>
							))}
							<div className='account-dropdown__footer'>
                        {auth.can(permisions.verConfiguracion) && (
                            <div className='account-dropdown__item'>
                                <Link to={getLink("/configuracion")} onClick={handleConfiguracionClick}>
                                    <i className='zmdi zmdi-settings'></i>
                                    Configuración
                                </Link>
								
                            </div>
                        )}
                    </div>

							<button onClick={auth.logout}>
								<i className="zmdi zmdi-power"></i>Logout
							</button>
						</div>
					</ul>
				</div>
			</nav>
		</header>
	);
};

export default MobileHeader;
