import React, { Component } from "react";
import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import { toast, formatCurrency, getLink, permisions } from "../../utils/helpers";
import Loader from "../general/Loader";
import Modal from "../general/Modal";
import { apiRequest } from "../../hooks/apiRequest";
import { itemContext } from "./itemContext";
import { postRequest } from "../../utils/request";
//import CreatableSelect from "react-select/creatable";


class Edit extends Component {
	state = {
		errors: {},
		showDelete: false,
		precios_venta: [],
		impuestos: [],
		unidades: [
            {value: 'UND', label: 'UND'},
            {value: 'PAQUETE', label: 'PAQUETE'},
            {value: 'JUEGO', label: 'JUEGO'},
            {value: 'PIES', label: 'PIES'},
            {value: 'PULG', label: 'PULG'},
            {value: 'MTS', label: 'MTS'},
            {value: 'CM', label: 'CM'},
            {value: 'GLS', label: 'GLS'},
            {value: 'LATA', label: 'LATA'},
            {value: 'CUBETA', label: 'CUBETA'},
            {value: 'CUARTOS', label: 'CUARTOS'},
            {value: 'LITROS', label: 'LITROS'},
            {value: 'LBS', label: 'LBS'},
            {value: 'KGS', label: 'KGS'},
        ],
		unidad: {},
		isLoading: false
	};

	componentDidMount() {
        this.initFormData();
		this.getImpuestos();
		this.getUnidades();
		this.costoField = React.createRef();
	}
	initFormData = () => {
        if(Object.keys(this.context).length <= 0) {
            setTimeout(() => {
                this.initFormData();
            }, 500);
            return;
        }
		const precios = this.context?.precios_venta?.map((precio) => ({
			id: precio.id,
			precio_venta: parseFloat(precio.precio_venta),
			costo: parseFloat(precio.porciento_beneficio),
			impuesto: precio.impuesto?.id || null,
			precio_total: parseFloat(precio.total),
		}));

        this.setState({
            precios_venta: precios,
        });
	};
	getImpuestos = async () => {
		const impuestos = await apiRequest(null, "impuestos-dropdown");

		if (impuestos.success) {
			this.setState({
				impuestos: impuestos.data,
			});
			return;
		}
	};
	getUnidades = async () => {
		const unidades = {}; //await apiRequest(null, "getUnidades");

		if (unidades.success) {
			this.setState({ unidades: unidades.data });
			return;
		}
	};
	createUnidad = async (inputValue) => {
		this.setState({ isLoading: true });
		const formData = new FormData();
		formData.append("seccion", "inventario");
		formData.append("nombre", "unidad");
		formData.append("valor", inputValue.toUpperCase());

		const {success, data} = await apiRequest(formData, "store-config", "post");

		if (success) {
			this.setState(state => ({ ...state, unidad: {value: data.valor, label: data.valor}, isLoading: false, unidades: [...state.unidades, {value: data.valor, label: data.valor}] }));
		}
	};
	handleUnidadChange = (newValue) => {
		this.setState({ unidad: newValue });
	};
	editUser = async (event) => {
		event.preventDefault();

		if (!auth.can(permisions.editarInventario)) return;


		const data = new FormData(event.target);

		const response = await postRequest(apiUrl(`items/${this.context.id}`), data);



		if (response.success) {
			this.props.history.push(getLink("/inventario"));
		}

		if (response.code === 422) {
			this.setState({ errors: response.data.message });
		}
	};
	toggleDeleteModal = () => {
		this.setState({
			showDelete: !this.state.showDelete,
		});
	};
	deleteItem = async (item) => {

		const url = `items/${item}`;

		const response = await postRequest(apiUrl(url), {}, 'delete');

		if (response.success) {
			this.props.history.push(getLink("/inventario"));
			toast("Item borrado.");
			return;
		} else {
			toast(response.data, "error");
		}
	};
	addPrecio = () => {
		this.setState((state) => ({
			precios_venta: [
				...state.precios_venta,
				{
					id: Date.now(),
					precio_venta: 0,
					impuesto: 12,
					precio_total: 0,
					costo: 0,
				},
			],
		}));
	};
	removePrecio = async (item) => {
		let res = await apiRequest(null, `precios/${item}`, "delete");

		if (res.code === 201) {
			this.setState((state) => ({
				...state,
				precios_venta: state.precios_venta.filter(
					(precio) => precio.id !== item
				),
			}));
		}
	};
	render() {
		const { errors, isLoading, unidades, unidad } = this.state;
		const { grupos, almacenes } = this.props;

		const notEditable = !auth.can(permisions.editarInventario);

		if (this.context === {}) {
			return <Loader />;
		}

		return (
			<div>
				<form
					method="post"
					onSubmit={this.editUser}
					className="col-md-10"
				>
					<input type="hidden" name="_method" value="patch" />
					<input type="hidden" name="item_id" value={this.context.id} />
					<input type="hidden" name="tipo" value={this.context.tipo} />
					<div className="row">
						<div className="col-6">
							<div className="form-group">
								<label className="label form-control-label">
									Nombre:
								</label>
								<input
									type="text"
									className="text-input form-control"
									id="nombre"
									name="nombre"
									disabled={notEditable}
									defaultValue={this.context.nombre}
								/>
								{errors.nombre && (
									<small className="help-blockParams form-Text">
										{errors.nombre[0]}
									</small>
								)}
							</div>
							<div className="form-group">
								<label className="label form-control-label">
									Referencia:
								</label>
								<input
									type="text"
									className="text-input form-control"
									id="referencia"
									name="referencia"
									disabled={notEditable}
									defaultValue={this.context.referencia}
								/>
								{errors.referencia && (
									<small className="help-blockParams form-Text">
										{errors.referencia[0]}
									</small>
								)}
							</div>
							{this.context.tipo === 'item' && (
								<div className="form-group">
									<label className="label form-control-label">
										Estado:
									</label>
										<select
											type="text"
											className="text-input form-control"
											id="estado"
											name="estado"
											disabled={notEditable}
											defaultValue={this.context.estado}
										>
											<option value="">
												- Seleccionar -
											</option>
											<option value="nuevo">Nuevo</option>
											<option value="usado">Usado</option>
										</select>
									{errors.estado && (
										<small className="help-blockParams form-Text">
											{errors.estado[0]}
										</small>
									)}
								</div>
							)}

							<div className="form-group">
								<label className="label form-control-label">
									Unidad:
								</label>
								<select name="unidad" className="form-control">
                                    {unidades.map(und => (<option key={und.value} selected={this.context.unidad === und.value} value={und.value}>{und.label}</option>))}
                                </select>
								{errors.unidad && (
									<small className="help-blockParams form-Text">
										{errors.unidad[0]}
									</small>
								)}
							</div>
						</div>
						<div className="col-6">
                        {grupos.length > 0 && this.context.grupo && (
								<div className="form-group">
									<label className="label form-control-label">
										Grupo:
									</label>
									<select
										className="text-input form-control"
										id="grupo_id"
										name="grupo_id"
										disabled={notEditable}
										defaultValue={
											this.context.grupo ? this.context.grupo.id : false
										}
									>
										<option value="">
											- Seleccionar -
										</option>
										{grupos.map((grupo) => (
											<option
												key={grupo.id}
												value={grupo.id}
											>
												{grupo.nombre}
											</option>
										))}
									</select>
									{errors.grupo_id && (
										<small className="help-blockParams form-Text">
											{errors.grupo_id[0]}
										</small>
									)}
								</div>
							)}
							{almacenes.length > 0 && this.context.almacen && (
								<div className="form-group">
									<label className="label form-control-label">
										Almacen:
									</label>
									<select
										className="text-input form-control"
										id="almacen_id"
										name="almacen_id"
										disabled={notEditable}
										defaultValue={
											this.context.almacen
												? this.context.almacen.id
												: false
										}
									>
										<option value="">
											- Seleccionar -
										</option>
										{almacenes.map((almacen) => (
											<option
												key={almacen.id}
												value={almacen.id}
											>
												{almacen.nombre}
											</option>
										))}
									</select>
									{errors.almacen_id && (
										<small className="help-blockParams form-Text">
											{errors.almacen_id[0]}
										</small>
									)}
								</div>
							)}
							<div className="form-group">
								<label className="label form-control-label">
									Descripción:
								</label>
								<textarea
									className="text-input form-control"
									id="descripcion"
									name="descripcion"
									rows="4"
									disabled={notEditable}
									defaultValue={this.context.descripcion}
								/>
								{errors.descripcion && (
									<small className="help-blockParams form-Text">
										{errors.descripcion[0]}
									</small>
								)}
							</div>
						</div>
					</div>

                    <table className="table m-t-20">
                        <thead>
                            <tr>
                                <th>Costo/Und:</th>
								<th>Impuesto:</th>
                                <th>Costo Total con Impuesto:</th>
                                <th>En Inventario:</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {formatCurrency(this.context.costo)}
                                    <input
                                        type="hidden"
                                        ref={this.costoField}
                                        value={this.context.costo}
                                    />
                                </td>
								<td>{this.context?.impuesto?.nombre}</td>
                                <td>
                                    {formatCurrency(parseFloat(this.context.costo) * (1 + parseFloat(this.context?.impuesto?.porcentaje ?? 0)))}
                                </td>
                                <td>
                                    {this.context.inventariable ? (
                                        this.context.cantidad
                                    ) : (
                                        <span>&infin;</span>
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>

					<div className="page-footer text-right">
						{!notEditable && (
							<button
								type="button"
								className="btn float-left"
								onClick={this.toggleDeleteModal}
							>
								<i className="fas fa-trash-alt"></i>
							</button>
						)}
						<button
							type="button"
							onClick={() =>
								this.props.history.push(getLink("/inventario"))
							}
							className="btn btn-plain"
						>
							Cancelar
						</button>
						{!notEditable && (
							<button type="submit" className="btn btn-primary">
								Guardar
							</button>
						)}
					</div>
				</form>
				<Modal
					title="Borrar Item"
					show={this.state.showDelete}
					callback={() => this.deleteItem(this.context.id)}
					acceptText="Borrar"
					toggle={this.toggleDeleteModal}
				>
					¿Estás seguro de que deseas borrar este item?
				</Modal>
			</div>
		);
	}
}
Edit.contextType = itemContext;

export default Edit;
