import React, { Component } from "react";
import { debounce } from "lodash";

import { withRequest } from "../../utils/request";
import Pagination from "../general/Pagination";
import Modal from "../general/Modal";
import Loader from "../general/Loader";
import { formatCurrency, getLink, money, permisions } from "../../utils/helpers";
import { Link } from "react-router-dom";
import { auth } from "../../utils/auth";
import ReporteForm from "./ReporteForm";
import Enviar from "./Enviar";
import ProductoFotos from "./ProductoFotos";
import FiltersContainer from "../general/FiltersContainer";
import PageHeader from "../general/PageHeader";
import ProductCatalogueExportForm from './ProductCatalogueExportForm';

class Productos extends Component {
	state = {
		productos: {},
		producto: {},
		almacenes: [],
		grupos: [],
		suplidores: [],
		links: null,
		showOperacionesInventario: false,
		showCreate: false,
		showEnviar: false,
		showFotos: false,
		showExportCatalogue: false,
		loading: true,
		filtrosQuery: "",
	};
	componentDidMount() {
		this.getProductos();
		this.getTable("almacenes");
		this.getTable("grupos");
		this.getSuplidores();
	}
	getProductos = async (url = null) => {
		url = url ? url + "&limit=20" : "productos?limit=20";
		const productos = await this.props.request(url);

		if (productos.data) {
			this.setState({
				productos: productos.data,
				meta: productos.meta,
				links: productos.links,
				loading: false,
			});
		}
	};
	getTable = async (table) => {
		const response = await this.props.request(table);

		if (response.data && response.data.length) {
			this.setState({
				[table]: response.data,
			});
		}
	};
	getSuplidores = async () => {
		const response = await this.props.request('contactos?tipo=proveedor&limit=1000');

		if (response.data && response.data.length) {
			this.setState({
				suplidores: response.data,
			});
		}
	};
	toggleCreateModal = () => {
		this.setState({
			showCreate: !this.state.showCreate,
			producto: {},
		});
	};
	toggleEnviarModal = (producto) => {
		this.setState({
			showEnviar: !this.state.showEnviar,
			producto: producto,
		});
	};
	toggleOperacionesInventarioModal = (producto) => {
		this.setState({
			showOperacionesInventario: !this.state.showOperacionesInventario
		});
	};
	toggleFotosModal = (producto) => {
		this.setState({
			showFotos: !this.state.showFotos,
			producto: producto,
		});
	};
	toggleCatalogueModal = () => {
		this.setState({
			showExportCatalogue: !this.state.showExportCatalogue,
		});
	};
	onFiltroChange = debounce(async (value, filtro) => {
		await this.setState({
			filtros: {
				...this.state.filtros,
				[filtro]: value,
			},
		});

		let query = Object.keys(this.state.filtros).map((fil) => {
			return `${fil}=${this.state.filtros[fil]}`;
		});

		await this.setState({
			filtrosQuery: "?" + query.join("&"),
		});

		this.getProductos("productos?" + query.join("&"));
	}, 500);
	render() {
		const { productos, almacenes, grupos, suplidores, loading, filtrosQuery } =
			this.state;
		return (
			<div>
				<PageHeader
					title="Productos/Servicios"
					titleDescription="Registre productos y servicios para venta."
				>
					<button onClick={() => this.toggleCatalogueModal()} className="au-btn au-btn-icon au-btn-filter m-r-10">
					<i className="zmdi zmdi-download"></i> Exportar Cat&aacute;logo
					</button>
					<div className="dropdown m-r-10">
						<button className="au-btn au-btn-icon au-btn-filter">
							<i className="zmdi zmdi-print"></i> Reportes
						</button>
						<ul
							style={{ right: "0", left: "auto" }}
							className="dropdown-menu"
						>
							<li>
								<Link
									to={getLink(
										`/imprimir/realizar-inventario${filtrosQuery}`
									)}
									target="_blank"
									className="dropdown-item"
								>
									Realizar Inventario
								</Link>
							</li>
							<li>
								<Link
									to={getLink(
										`/imprimir/productos${filtrosQuery}`
									)}
									target="_blank"
									className="dropdown-item"
								>
									Inventario
								</Link>
							</li>
							<li>
								<Link
									to={getLink(
										`/imprimir/reorden${filtrosQuery}`
									)}
									target="_blank"
									className="dropdown-item"
								>
									Reorden
								</Link>
							</li>
							<li>
								<Link
									to={getLink(
										`/imprimir/reportes?reporte=balance-inventario${filtrosQuery}`
									)}
									target="_blank"
									className="dropdown-item"
								>
									Balance Inventario
								</Link>
							</li>
							<li>
								<button
									onClick={this.toggleOperacionesInventarioModal}
									className="dropdown-item"
								>
									Operaciones de Inventario
								</button>
							</li>
							<li>
								<Link
									to={getLink(
										`/imprimir/ventas${filtrosQuery}`
									)}
									target="_blank"
									className="dropdown-item"
								>
									Inventario Ventas
								</Link>
							</li>
							<li>
								<Link
									to={getLink(
										`/imprimir/productos-suplidor${filtrosQuery}`
									)}
									target="_blank"
									className="dropdown-item"
								>
									Productos por Suplidor
								</Link>
							</li>
						</ul>
					</div>

					{auth.can(permisions.editarProductos) && (
						<Link
							to={getLink(`/productos/crear`)}
							className="au-btn au-btn-icon au-btn--green au-btn--small"
						>
							<i className="zmdi zmdi-plus" />
							Añadir Producto/servicio
						</Link>
					)}
				</PageHeader>
				<FiltersContainer>
					<div className="col-md-6">
						<label htmlFor="search">Buscar</label>
						<input
							onChange={(e) =>
								this.onFiltroChange(e.target.value, "buscar")
							}
							type="search"
							placeholder="Buscar..."
							className="form-control"
							name="buscar"
						/>
					</div>
					<div className="col-md-2">
						<label htmlFor="almacen">Almacén</label>
						<select
							className="form-control"
							name="almacen"
							id="almacen"
							onChange={(e) =>
								this.onFiltroChange(e.target.value, "almacen")
							}
						>
							<option value="todos">Todos</option>
							{almacenes.map((almacen) => (
								<option key={almacen.id} value={almacen.id}>
									{almacen.nombre}
								</option>
							))}
						</select>
					</div>
					<div className="col-md-2">
						<label htmlFor="grupo">Categoría</label>
						<select
							className="form-control"
							name="grupo"
							id="grupo"
							onChange={(e) =>
								this.onFiltroChange(e.target.value, "grupo")
							}
						>
							<option value="todos">Todos</option>
							{grupos.map((grupo) => (
								<option key={grupo.id} value={grupo.id}>
									{grupo.nombre}
								</option>
							))}
						</select>
					</div>
					<div className="col-md-2">
						<label htmlFor="tipo">Tipo</label>
						<select
							className="form-control"
							name="tipo"
							id="tipo"
							onChange={(e) =>
								this.onFiltroChange(e.target.value, "tipo")
							}
						>
							<option value="todos">Todos</option>
							<option value="producto">Productos</option>
							<option value="servicio">Servicios</option>
						</select>
					</div>
				</FiltersContainer>
				<div className="row">
					<div className="col-md-12">
						<div>
							{loading && <Loader />}
							{!productos.length ? (
								<div>
									{!loading && (
										<div>
											Todavía no hay productos creados.
										</div>
									)}
								</div>
							) : (
								<div className="table-data">
									<table className="table table-data2">
										<thead>
											<tr>
												<th>Nombre</th>
												<th>Ref.</th>
												<th>Ubicación</th>
												<th>Cantidad</th>
												<th>Precio Venta</th>
												<th>Total</th>
												<th>Descripción</th>
												<th />
											</tr>
										</thead>
										<tbody>
											{productos.map((producto) => (
												<tr
													className="tr-shadow"
													key={producto.id}
												>
													<td>{producto.nombre}</td>
													<td>
														{producto.referencia}
													</td>
													<td style={{ width: 130 }}>
														{producto.almacen &&
															producto.almacen
																.nombre}
													</td>
													{producto.inventariable ? (
														<td>
															{producto.cantidad}{" "}
															{producto.unidad}
														</td>
													) : (
														<td>&infin;</td>
													)}
													<td>
														{formatCurrency(
															money(producto.precio)
														)}
													</td>
													<td>
														{formatCurrency(
															money(producto.precio *
																producto.cantidad)
														)}
													</td>
													<td>
														{producto.descripcion}
													</td>
													<td>
														<div className="table-data-feature">
															{producto.fotos
																.length > 0 && (
																<button
																	title="Ver Fotos"
																	onClick={() =>
																		this.toggleFotosModal(
																			producto
																		)
																	}
																	className="item"
																>
																	<i className="fas fa-eye"></i>
																</button>
															)}
															{producto.tipo ===
																"producto" &&
																producto.cantidad >
																	0 && (
																	<button
																		title="Enviar"
																		onClick={() =>
																			this.toggleEnviarModal(
																				producto
																			)
																		}
																		className="item"
																	>
																		<i className="zmdi zmdi-truck" />
																	</button>
																)}
															<Link
																data-toggle="tooltip"
																data-placement="top"
																title="Editar"
																data-original-title="Edit"
																className="item"
																to={getLink(
																	`/productos/${producto.id}/informacion`
																)}
															>
																<i className="zmdi zmdi-edit" />
															</Link>
														</div>
													</td>
												</tr>
											))}
										</tbody>
									</table>
									{this.state.links && (
										<Pagination
											links={this.state.links}
											meta={this.state.meta}
											getData={this.getProductos}
										/>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
				<Modal
					show={this.state.showEnviar}
					blank={true}
					toggle={() => {
						this.setState({
							showEnviar: !this.state.showEnviar,
						});
					}}
				>
					<Enviar
						toggle={() => {
							this.setState({
								showEnviar: !this.state.showEnviar,
							});
						}}
						producto={this.state.producto}
						getProductos={this.getProductos}
						almacenes={almacenes}
					/>
				</Modal>
				<Modal
					show={this.state.showOperacionesInventario}
					blank={true}
					size={"600px"}
					toggle={() => {
						this.setState({
							showOperacionesInventario: !this.state.showOperacionesInventario,
						});
					}}
				>
					<ReporteForm
						toggle={() => {
							this.setState({
								showOperacionesInventario: !this.state.showOperacionesInventario,
							});
						}}
					/>
				</Modal>
				{this.state.producto?.fotos && (
					<Modal
						show={this.state.showFotos}
						blank={true}
						toggle={() => {
							this.setState({
								showFotos: !this.state.showFotos,
							});
						}}
					>
						<ProductoFotos
							toggle={() => {
								this.setState({
									showFotos: !this.state.showFotos,
								});
							}}
							fotos={this.state.producto.fotos.map(
								(foto) => foto.url
							)}
						/>
					</Modal>
				)}
				<Modal
					show={this.state.showExportCatalogue}
					blank={true}
					toggle={() => {
						this.setState({
							showExportCatalogue: !this.state.showExportCatalogue,
						});
					}}
				>
					<ProductCatalogueExportForm
						toggle={() => {
							this.setState({
								showExportCatalogue: !this.state.showExportCatalogue,
							});
						}}
						grupos={grupos}
						suplidores={suplidores}
						request={this.props.request}
					/>
				</Modal>
			</div>
		);
	}
}

export default withRequest(Productos);
